<script setup lang="ts">
  import {ref} from "vue";
  import {Input} from "@/components/ui/input";
  import { Eye, EyeOff } from 'lucide-vue-next'
  import {useVModel} from "@vueuse/core";

  const isPasswordVisible = ref(false);

  const props = defineProps<{
    placeholder: string
    defaultValue?: string | number
    modelValue?: string | number
  }>()

  const emits = defineEmits<{
    (e: 'update:modelValue', payload: string | number): void
  }>()

  const modelValue = useVModel(props, 'modelValue', emits, {
    passive: true,
    defaultValue: props.defaultValue,
  })

  const togglePasswordVisibility = (value: boolean) => {
    isPasswordVisible.value = value;
  };
</script>

<template>
  <div class="relative">
    <Input
        v-model="modelValue"
        id="password"
        :placeholder="props.placeholder"
        :type="isPasswordVisible ? 'text' : 'password'"
        auto-correct="off"
        class="pr-12"
    />
    <div class="cursor-pointer absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md bg-gray-100 border border-l-gray-200" @click.prevent="togglePasswordVisibility(!isPasswordVisible)">
      <EyeOff v-if="isPasswordVisible" class="h-5 w-5 text-black"></EyeOff>
      <Eye v-else class="h-5 w-5 text-black"></Eye>
    </div>
  </div>
</template>

<style scoped>

</style>
