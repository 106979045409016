<script setup lang="ts">
  import { ref } from 'vue'
  import {cn, toast} from '@/utils/utils'
  import { Button } from "@/components/ui/button";
  import SigninService from "@/services/signin/signin.service";
  import {useRoute} from "vue-router";
  import router from "@/router";
  import PasswordInput from "@/components/ui_custom/input/PasswordInput.vue";
  import {toTypedSchema} from "@vee-validate/zod";
  import * as z from "zod";
  import {useForm} from "vee-validate";
  import {FormControl, FormField, FormItem, FormMessage} from "@/components/ui/form";
  import {i18n} from "@/utils/i18n";

  const isLoading = ref(false);
  const route = useRoute();
  const formSchema = toTypedSchema(z.object({
    password: z.string(),
    confirmPassword: z.string(),
  })
      .refine((data) => data.password === data.confirmPassword , { message: i18n.global.t('validations.sameAsPassword'), path: ['confirmPassword'] })
  )
  const { handleSubmit } = useForm({
    validationSchema: formSchema,
  })

  const onSubmit = handleSubmit(async (values) => {
    const res = await SigninService.resetPassword(values.password, route.params.email as string, route.params.token as string);
    if (res?.data.statusCode === 200) {
      isLoading.value = false;
      await router.push({name: 'signin'});
      toast.success(res.data.message);
    } else {
      toast.error(res?.data.message);
    }
    isLoading.value = false;

    setTimeout(() => {
      isLoading.value = false
    }, 3000)
  })
</script>

<template>
  <div :class="cn('grid gap-6')">
    <form @submit.prevent="onSubmit">
      <div class="grid gap-2">
        <div class="grid gap-1">
          <FormField v-slot="{ componentField }" name="password">
            <FormItem class="w-full">
              <FormControl>
                <PasswordInput :placeholder="$t('column.password')" v-bind="componentField" auto-correct="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="confirmPassword">
            <FormItem class="w-full">
              <FormControl>
                <PasswordInput :placeholder="$t('column.confirm_password')" v-bind="componentField" auto-correct="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>
        <Button :disabled="isLoading">
          {{ $t('login.reset_password') }}
        </Button>
      </div>
    </form>
  </div>
</template>

<style scoped>

</style>
