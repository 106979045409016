import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import {Updater} from "@tanstack/vue-table";
import {Ref} from "vue";
import {useToast} from "vue-toastification";

const initializeToast = useToast();

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function valueUpdater<T extends Updater<any>>(updaterOrValue: T, ref: Ref) {
  ref.value
      = typeof updaterOrValue === 'function'
      ? updaterOrValue(ref.value)
      : updaterOrValue
}

export const loggedUser = {
  getLoggedUserFromStorage: () => {
    let user = {};
    const userStorage = localStorage.getItem('user');
    if (userStorage) {
      user = JSON.parse(userStorage);
    }
    return user;
  }
}

export const toast = {
  success: (text: string, options?: object) => {
    return initializeToast.success(text, options)
  },
  error: (text: string, options?: object) => {
    return initializeToast.error(text, options)
  },
}
