import { h } from 'vue'
import { ColumnDef } from "@tanstack/vue-table";
import DropdownAction from '@/components/tables/managers/ManagersDataTableDropDown.vue'
import {i18n} from "@/utils/i18n";
import {Manager} from "@/models/tables/manager.interface";

export const columns: ColumnDef<Manager>[] = [
    {
        accessorKey: 'id',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.id')),
        cell: ({ row }) => {
            const id = Number(row.getValue('id'));
            return h('div', { class: 'text-right font-medium' }, id)
        },
    },
    {
        accessorKey: 'name',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.name')),
        cell: ({ row }) => {
            const name = String(row.getValue('name'));
            return h('div', { class: 'text-right font-medium' }, name)
        },
    },
    {
        accessorKey: 'address',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.address')),
        cell: ({ row }) => {
            const address = String(row.original.address);
            const city = String(row.original.city);
            const zip = String(row.original.zip);
            const province = String(row.original.province);
            const state = String(row.original.state);
            const formattedAddress = address+' '+zip+' '+city+' ('+province+') '+state;
            return h('div', { class: 'text-right font-medium' }, formattedAddress)
        },
    },
    {
        accessorKey: 'enabled',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('table.enabled')),
        cell: ({ row }) => {
            const enabled = Boolean(row.getValue('enabled'));
            let i18nEnabled = i18n.global.t('table.disabled');
            if (enabled) {
                i18nEnabled = i18n.global.t('table.enabled');
            }
            return h('div', { class: 'text-right font-medium' }, i18nEnabled)
        },
    },
    {
        id: 'actions',
        cell: ({ row }) => {
            const object = row.original
            return h('div', { class: 'relative' }, h(DropdownAction, { object, entity: 'managers' }))
        },
    },
]
