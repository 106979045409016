<script setup lang="ts" generic="TData, TValue">
  import { Plus } from 'lucide-vue-next'
  import {
    ColumnDef,
    FlexRender,
    getCoreRowModel, getFilteredRowModel, getPaginationRowModel,
    useVueTable,
  } from '@tanstack/vue-table'
  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from '@/components/ui/table'
  import { Button } from "@/components/ui/button";
  import { ref } from "vue";
  import { valueUpdater } from '@/utils/utils'
  import { Input } from "@/components/ui/input";
  import useEventsBus from "@/utils/eventBus";

  const props = defineProps<{
    // eslint-disable-next-line no-undef
    columns: ColumnDef<TData, TValue>[]
    // eslint-disable-next-line no-undef
    data: TData[]
    entity: string
  }>()

  const globalFilter = ref('');
  const setGlobalFilter = (value: string) => {
    globalFilter.value = value;
  };
  const { emit } = useEventsBus();

  const table = useVueTable({
    get data() { return props.data },
    get columns() { return props.columns },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: updaterOrValue => valueUpdater(updaterOrValue, globalFilter),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      get globalFilter() { return globalFilter.value },
    },
  })
</script>

<template>
  <div>
    <div class="flex items-center py-4">
      <Input class="max-w-sm" :placeholder="$t('table.search')"
             :model-value="globalFilter || ''"
             @update:model-value="value => setGlobalFilter(String(value))" />
      <Button class="ml-auto" @click="emit('new_'+props.entity, {})">
        <Plus class="mr-2 h-5 w-5" />
        {{ $t('table.add') }}
      </Button>
    </div>
    <div class="border rounded-md">
      <Table>
        <TableHeader class="bg-gray-300 font-black">
          <TableRow v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
            <TableHead v-for="header in headerGroup.headers" :key="header.id">
              <FlexRender v-if="!header.isPlaceholder" :render="header.column.columnDef.header"
                          :props="header.getContext()" />
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <template v-if="table.getRowModel().rows?.length">
            <TableRow v-for="row in table.getRowModel().rows" :key="row.id"
                      :data-state="row.getIsSelected() ? 'selected' : undefined">
              <TableCell v-for="cell in row.getVisibleCells()" :key="cell.id">
                <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
              </TableCell>
            </TableRow>
          </template>
          <template v-else>
            <TableRow>
              <TableCell :colSpan="columns.length" class="h-24 text-center">
                {{ $t('table.no_result') }}
              </TableCell>
            </TableRow>
          </template>
        </TableBody>
      </Table>
    </div>
    <div class="flex items-center justify-end py-4 space-x-2">
      <Button
          variant="outline"
          size="sm"
          :disabled="!table.getCanPreviousPage()"
          @click="table.previousPage()"
      >
        {{ $t('table.previous') }}
      </Button>
      <Button
          variant="outline"
          size="sm"
          :disabled="!table.getCanNextPage()"
          @click="table.nextPage()"
      >
        {{ $t('table.next') }}
      </Button>
    </div>
  </div>
</template>

<style scoped>

</style>
