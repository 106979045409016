<script setup lang="ts">
import {StepBack, Trash2, Download} from "lucide-vue-next";
import {Button} from "@/components/ui/button";
import {onBeforeMount, ref, watch} from "vue";
import TablesService from "@/services/tables/tables.service";
import {useRoute} from "vue-router";
import Spinner from "@/components/ui_custom/spinner/Spinner.vue";
import useEventsBus from "@/utils/eventBus";
import {i18n} from "@/utils/i18n";
import {toast} from "@/utils/utils";
import DeleteDialog from "@/components/tables/DeleteDialog.vue";

const items: any = ref([]);
const selected: any = ref([]);
const route = useRoute();
const { bus } = useEventsBus();
let isLoading = ref(false);
const files: any = {
      md: 'mdi-language-markdown',
      pdf: 'mdi-file-pdf-box',
      png: 'mdi-file-image',
      txt: 'mdi-file-document-outline',
      xls: 'mdi-file-excel',
};
const isDeleteDialogOpen = ref(false);

const setIsDeleteDialogOpen = (value: boolean) => {
  isDeleteDialogOpen.value = value;
};

watch(() => bus.value.get('delete_aDocuments'), async () => {
  isLoading.value = true;
  const response = await TablesService.bulkDeleteAttachments(selected.value);
  if (response?.status === 200) {
    const res = await TablesService.getTreeView(route.params.entity as string, route.params.id as string);
    items.value = res?.data
    selected.value = [];
    isLoading.value = false;
    toast.success(i18n.global.t('toast.delete_success'));
  }
})

async function forceFileDownload(item: any) {
  isLoading.value = true;
  const attachment = await TablesService.getAttachmentById(item.id as string, 'documents');
  if (attachment) {
    const link = document.createElement('a')
    link.href = "data:" + item.mimeType + ";base64," + attachment.data
    link.download = item.originalFileName;
    link.click();
  }
  isLoading.value = false;
}

function createSelectedArray(value: any) {
  if (selected.value.includes(value.id)) {
    selected.value = selected.value.filter((selectedId: number) => selectedId !== value.id)
  } else {
    selected.value.push(value.id)
  }
}

onBeforeMount(async () => {
  isLoading.value = true;
  const res = await TablesService.getTreeView(route.params.entity as string, route.params.id as string);
  items.value = res?.data;
  isLoading.value = false;
})
</script>

<template>
  <Spinner
      :is-loading="isLoading"
  />
  <div class="flex w-5/6">
    <div v-if="items.length > 0" class="m-[2%] w-5/6">
      <div class="flex justify-between items-center">
        <Button @click.prevent="$router.back()" type="button" class="text-center">
          <StepBack class="mr-2 h-5 w-5" />
          {{ $t('building.back') }}
        </Button>
        <p v-if="route.params.entity === 'buildings'" class="font-bold light:text-black dark:text-white text-xl">{{ $t('building.documents_list_building') }}</p>
        <p v-else class="font-bold light:text-black dark:text-white text-xl">{{ $t('building.documents_list_roof') }}</p>
      </div>
      <Button @click.prevent="setIsDeleteDialogOpen(true)" class="text-center mt-4" v-if="selected.length > 0">
        <Trash2 class="mr-2 h-4 w-4" />
        {{ $t('table.delete') }}
      </Button>
      <v-container>
        <v-treeview
            :items="items"
            activatable
            selectable
            select-strategy="leaf"
            :item-value="item => item.id"
            @click:select="value => createSelectedArray(value)"
        >
          <template v-slot:prepend="{ item }: any">
            <label v-if="!item.fileName" class="cursor-default">
              <v-icon class="mr-2">
                {{ 'mdi-folder' }}
              </v-icon>
            </label>
            <label v-else class="cursor-default">
              <v-icon>
                {{ files[item.mimeType.split('/')[1]] }}
              </v-icon>
              {{ item.originalFileName }}
            </label>
          </template>
          <template v-slot:append="{ item }: any">
            <Button v-if="item.fileName" @click="forceFileDownload(item)">
              <Download />
            </Button>
          </template>
        </v-treeview>
      </v-container>
    </div>
    <div v-else class="m-[2%] w-5/6">
      <div class="flex justify-between items-center">
        <Button @click.prevent="$router.back()" type="button" class="text-center">
          <StepBack class="mr-2 h-5 w-5" />
          {{ $t('building.back') }}
        </Button>
        <p v-if="route.params.entity === 'buildings'" class="font-bold text-black text-xl">{{ $t('building.documents_list_building') }}</p>
        <p v-else class="font-bold text-black text-xl">{{ $t('building.documents_list_roof') }}</p>
      </div>
      <div class="content-center h-full">
        <p class="text-7xl text-gray-500 font-bold text-center uppercase">
          {{ $t('building.no_documents') }}
        </p>
      </div>
    </div>
  </div>
  <DeleteDialog entity="attachments" :a-id="selected" :open="isDeleteDialogOpen" :set-open="setIsDeleteDialogOpen" />
</template>

<style scoped>

</style>
