<script setup lang="ts">
import {Carousel, CarouselContent, CarouselItem} from "@/components/ui/carousel";
import {Card, CardContent} from "@/components/ui/card";
import Autoplay from "embla-carousel-autoplay";
import TablesService from "@/services/tables/tables.service";
import {ref} from "vue";

const props = defineProps<{
  entity: string,
  entityId: string
}>()
const photos: any = ref([]);

async function setApi() {
  const res = await TablesService.getAttachments(props.entity, props.entityId, 'photos');
  photos.value = res?.data
}
</script>

<template>
  <Carousel
      class="relative w-[80%] h-full"
      :plugins="[Autoplay({
        delay: 6000,
      })]"
      @init-api="setApi"
  >
    <CarouselContent>
      <CarouselItem v-for="photo in photos" :key="photo.id">
        <div class="px-4">
          <Card>
            <CardContent class="flex aspect-square items-center justify-center p-0">
              <img :src="`data:${photo.mimeType};base64, ${photo.data}`" :alt="photo.filename" class="h-[100%] w-[100%] object-cover rounded-lg">
            </CardContent>
          </Card>
        </div>
      </CarouselItem>
    </CarouselContent>
  </Carousel>
</template>

<style scoped>

</style>
