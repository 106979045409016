<script setup lang="ts">
  import SigninForm from "@/components/signin/SigninForm.vue";

  const version = process.env.VUE_APP_VERSION;
</script>

<template>
  <div class="container relative hidden h-[100vh] flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
    <div class="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
      <div class="absolute inset-0 signin-bg" />
<!--      <div class="relative z-20 flex items-center text-lg font-medium">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="mr-2 h-6 w-6"
        >
          <path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3" />
        </svg>
        Acme Inc
      </div>-->
      <div class="relative z-20 mt-auto">
        <blockquote class="space-y-2">
          <p class="text-lg bg-black bg-opacity-40 p-2">
            &ldquo;Salvaguardia su Misura: Proteggi il Tetto, Preserva il Futuro&rdquo;<br /><br />
            Questo slogan mette in luce l'importanza della personalizzazione del servizio, la protezione dell'edificio e la visione a lungo termine, tutti elementi chiave per la manutenzione dei tetti.
          </p>
          <p class="text-lg bg-black bg-opacity-40 p-2">
            Software version: {{version}}
          </p>
        </blockquote>
      </div>
    </div>
    <div class="lg:p-8">
      <div class="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
        <div class="flex flex-col space-y-2 text-center">
          <h1 class="text-2xl font-semibold tracking-tight">
            {{ $t('login.login') }}
          </h1>
          <p class="text-sm text-muted-foreground">
            {{ $t('login.subtitle') }}
          </p>
        </div>
        <SigninForm />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
