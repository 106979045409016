<script setup lang="ts">
  import {Button} from "@/components/ui/button";
  import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter, DialogHeader, DialogTitle
  } from "@/components/ui/dialog";
  import {Input} from "@/components/ui/input";
  import TablesService from "@/services/tables/tables.service";
  import {Switch} from "@/components/ui/switch";
  import useEventsBus from "@/utils/eventBus";
  import {Separator} from "@/components/ui/separator";
  import {toTypedSchema} from "@vee-validate/zod";
  import * as z from 'zod'
  import {useForm} from "vee-validate";
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from '@/components/ui/form'
  import {Textarea} from "@/components/ui/textarea";
  import {watch} from "vue";
  import VueMultiselect from "vue-multiselect";

  const props = defineProps<{
    object: any,
    entity: string,
    open: boolean,
    setOpen: any,
  }>()
  const { emit } = useEventsBus();
  let coverageElement: any = {};
  let defects: any = [];
  const formSchema = toTypedSchema(z.object({
    code: z.string(),
    description: z.string(),
    enabled: z.boolean().default(true),
    defects: z.any()
  }))
  const { handleSubmit, values, setValues } = useForm({
    validationSchema: formSchema,
  })

  watch(values, (newFormData) => {
    coverageElement = newFormData;
  });

  async function getDefects() {
    const res = await TablesService.getData('defects')
    defects = res?.data
  }

  const onSubmit = handleSubmit(async () => {
    let res;
    res = await TablesService.createRecord(coverageElement, props.entity);
    if (res != undefined) {
      emit('update_'+props.entity, res.data);
    }
  })

  watch(() => props.open, async(_, newValue) => {
    if (newValue === false) {
      await getDefects();
      if (props.object.id) {
        setValues(props.object);
      }
    }
  },{ immediate: true })

  function multiselectLabel(option: any) {
    return `${option.code} - R: ${option.detectability} G: ${option.gravity}`
  }
</script>

<template>
  <Dialog :open="props.open" @update:open="props.setOpen">
    <DialogContent class="max-w-[60vw]">
      <DialogHeader>
        <DialogTitle v-if="!props.object.id">{{ $t('table.add') }}</DialogTitle>
        <DialogTitle v-else>{{ $t('table.edit') }}</DialogTitle>
        <form @submit="onSubmit" class="mt-3">
          <FormField v-slot="{ componentField }" name="code">
            <FormItem>
              <FormLabel>{{ $t('column.code') }}</FormLabel>
              <FormControl>
                <Input type="text" :placeholder="$t('column.code')" v-bind="componentField" auto-correct="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="description">
            <FormItem>
              <FormLabel>{{ $t('column.description') }}</FormLabel>
              <FormControl>
                <Textarea :placeholder="$t('column.description')" v-bind="componentField" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <Separator class="my-1" />
          <FormField v-slot="{ componentField }" name="defects">
            <FormItem class="w-full">
              <FormLabel>{{ $t('column.defects') }}</FormLabel>
              <FormControl>
                <VueMultiselect
                    v-bind="componentField"
                    :options="defects"
                    :multiple="true"
                    :searchable="false"
                    track-by="code"
                    :custom-label="multiselectLabel"
                >
                </VueMultiselect>
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <Separator class="my-1" />
          <FormField v-slot="{ value, handleChange }" name="enabled">
            <FormItem class="flex flex-row items-center gap-4 p-4">
              <div class="space-y-0.5">
                <FormLabel class="text-base">
                  {{ $t('table.enabled') }}
                </FormLabel>
              </div>
              <FormControl>
                <Switch :checked="value" @update:checked="handleChange" />
              </FormControl>
            </FormItem>
          </FormField>
          <DialogFooter>
            <DialogClose as-child>
              <Button type="button" variant="secondary">
                {{ $t('table.cancel') }}
              </Button>
            </DialogClose>
            <Button type="submit">
              {{ $t('table.confirm') }}
            </Button>
          </DialogFooter>
        </form>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
