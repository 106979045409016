import axios from "axios";
import {toast} from "@/utils/utils";

export default class SharedService {
    static async dashboard() {
        try {
            return await axios.get(process.env.VUE_APP_BASE_API_URL + 'shared/dashboard', {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }

}
