<script setup lang="ts">
  import {Button} from "@/components/ui/button";
  import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter, DialogHeader, DialogTitle
  } from "@/components/ui/dialog";
  import {Input} from "@/components/ui/input";
  import TablesService from "@/services/tables/tables.service";
  import useEventsBus from "@/utils/eventBus";
  import {Separator} from "@/components/ui/separator";
  import {toTypedSchema} from "@vee-validate/zod";
  import * as z from "zod";
  import {useForm} from "vee-validate";
  import {computed, ref, watch} from "vue";
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from '@/components/ui/form'
  import { CalendarDate, DateFormatter, getLocalTimeZone, parseDate, today } from '@internationalized/date'
  import { Calendar } from '@/components/ui/calendar'
  import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
  import {cn} from "@/utils/utils";
  import {Calendar as CalendarIcon, Check, ChevronsUpDown} from 'lucide-vue-next'
  import {i18n} from "@/utils/i18n";
  import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "@/components/ui/command";

  const props = defineProps<{
    object: any,
    entity: string,
    open: boolean,
    setOpen: any,
  }>()
  const { emit } = useEventsBus();
  let users: any = [];
  let coverages: any = [];
  let serviceOrder: any = {};
  const formSchema = toTypedSchema(z.object({
    date: z.string(),
    user: z.number(),
    coverage: z.number(),
  }))
  const { handleSubmit, values, setValues } = useForm({
    validationSchema: formSchema,
  })
  const df = new DateFormatter('it-IT', {
    dateStyle: 'long',
  })
  const date = computed({
    get: () => values.date ? parseDate(values.date) : undefined,
    set: val => val,
  })
  const datePlaceholder = ref();
  const usersOpen = ref(false);
  const coveragesOpen = ref(false);

  watch(values, (newFormData) => {
    serviceOrder = newFormData;
  });

  const onSubmit = handleSubmit(async () => {
    let res;
    if (!props.object.id) {
      res = await TablesService.createRecord(serviceOrder, props.entity);
    } else {
      res = await TablesService.updateRecord(serviceOrder, props.entity);
    }
    if (res != undefined) {
      emit('update_'+props.entity, res.data);
    }
  })

  async function getUsers() {
    const res = await TablesService.getData('users')
    users = res?.data
  }

  async function getCoverages() {
    const res = await TablesService.getData('coverages')
    coverages = res?.data
  }

  watch(() => props.open, async(_, newValue) => {
    if (newValue === false) {
      await getUsers();
      await getCoverages();
      if (props.object.id) {
        await initializeForm()
      }
    }
  },{ immediate: true })

  async function initializeForm() {
    const res = await TablesService.getElementById(props.entity, props.object.id);
    if (res?.data) {
      const formValues = res.data
      const date = new Date(formValues.date);
      formValues.date = new CalendarDate(date.getFullYear(), date.getMonth(), date.getDay()).toString();
      formValues.coverage = formValues.coverage.id
      formValues.user = formValues.user.id
      setValues(formValues);
    }
  }
</script>

<template>
  <Dialog :open="props.open" @update:open="props.setOpen">
    <DialogContent class="max-w-[60vw]">
      <DialogHeader>
        <DialogTitle v-if="!props.object.id">{{ $t('table.add') }}</DialogTitle>
        <DialogTitle v-else>{{ $t('table.edit') }}</DialogTitle>
        <form @submit="onSubmit" class="mt-3">
          <FormField name="date">
            <FormItem class="flex flex-col">
              <FormLabel>{{$t('column.date')}}</FormLabel>
              <Popover>
                <PopoverTrigger as-child>
                  <FormControl>
                    <Button
                        variant="outline" :class="cn(
                  'w-full ps-3 text-start font-normal',
                  !date && 'text-muted-foreground',
                )"
                    >
                      <span>{{ date ? df.format(new Date(date as any)) : i18n.global.t('column.date') }}</span>
                      <CalendarIcon class="ms-auto h-4 w-4 opacity-50" />
                    </Button>
                    <input hidden>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent class="w-auto p-0">
                  <Calendar
                      v-model:placeholder="datePlaceholder"
                      v-model="date"
                      :calendar-label="i18n.global.t('column.date')"
                      initial-focus
                      :min-value="new CalendarDate(2000, 1, 1)"
                      @update:model-value="(v) => {
                if (v) {
                  setValues({
                    date: v.toString(),
                  })
                }
                else {
                  setValues({
                    date: '',
                  })
                }

              }"
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          </FormField>
          <Separator class="my-1" />
          <div class="flex gap-4">
            <FormField name="user">
              <FormItem class="flex flex-col w-full">
                <FormLabel>{{ $t('column.user') }}</FormLabel>
                <Popover v-model:open="usersOpen">
                  <PopoverTrigger as-child>
                    <FormControl>
                      <Button
                          variant="outline"
                          role="combobox"
                          :class="cn('w-full justify-between', !values.user && 'text-muted-foreground')"
                      >
                        {{ values.user ? users.find(
                          (user: any) => user.id === values.user,
                      )?.name + ' ' + users.find(
                          (user: any) => user.id === values.user,
                      )?.surname : i18n.global.t('table.select') }}
                        <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent class="w-[45vw] p-0">
                    <Command>
                      <CommandInput :placeholder="$t('table.search')" />
                      <CommandEmpty>{{$t('table.no_result')}}</CommandEmpty>
                      <CommandList>
                        <CommandGroup>
                          <CommandItem
                              v-for="user in users"
                              :key="user.id"
                              :value="user.id"
                              @select="() => {
                              setValues({
                                user: user.id,
                              })
                              usersOpen = false
                            }"
                          >
                            <Check
                                :class="cn('mr-2 h-4 w-4', user.id === values.user ? 'opacity-100' : 'opacity-0')"
                            />
                            {{ user.name }} {{ user.surname }}
                          </CommandItem>
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            </FormField>
            <FormField name="coverage">
              <FormItem class="flex flex-col w-full">
                <FormLabel>{{ $t('column.coverage') }}</FormLabel>
                <Popover v-model:open="coveragesOpen">
                  <PopoverTrigger as-child>
                    <FormControl>
                      <Button
                          variant="outline"
                          role="combobox"
                          :class="cn('w-full justify-between', !values.coverage && 'text-muted-foreground')"
                      >
                        {{ values.coverage ? coverages.find(
                          (coverage: any) => coverage.id === values.coverage,
                      )?.name : i18n.global.t('table.select') }}
                        <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent class="w-[45vw] p-0">
                    <Command>
                      <CommandInput :placeholder="$t('table.search')" />
                      <CommandEmpty>{{$t('table.no_result')}}</CommandEmpty>
                      <CommandList>
                        <CommandGroup>
                          <CommandItem
                              v-for="coverage in coverages"
                              :key="coverage.id"
                              :value="coverage.id"
                              @select="() => {
                              setValues({
                                coverage: coverage.id,
                              })
                              coveragesOpen = false
                            }"
                          >
                            <Check
                                :class="cn('mr-2 h-4 w-4', coverage.id === values.coverage ? 'opacity-100' : 'opacity-0')"
                            />
                            {{ coverage.name }}
                          </CommandItem>
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
          <DialogFooter class="mt-6">
            <DialogClose as-child>
              <Button type="button" variant="secondary">
                {{ $t('table.cancel') }}
              </Button>
            </DialogClose>
            <Button type="submit">
              {{ $t('table.confirm') }}
            </Button>
          </DialogFooter>
        </form>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
