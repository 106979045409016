<script setup lang="ts">
  import {
    Dialog,
    DialogDescription,
    DialogContent,
    DialogHeader,
    DialogTitle,
  } from '@/components/ui/dialog'

  const props = defineProps<{
    object: object,
    open: boolean
    setOpen: any
  }>()
</script>

<template>
  <Dialog :open="props.open" @update:open="props.setOpen">
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{{ $t('building.calculation_data') }}</DialogTitle>
        <DialogDescription class="light:text-black dark:text-white">
          <div v-for="(data, key) in props.object" :key="key" class="mb-4">
            <div class="font-bold grid text-red-600">
              <p>{{ data.defect.code }}</p>
            </div>
            <div class="flex gap-4">
              <p><span class="font-bold">{{ $t('column.defect_dimensions') }}</span> - {{ data.defectDimensions }}</p>
              <p><span class="font-bold">{{ $t('column.total') }}</span> - {{ data.defectTotal }}</p>
            </div>
            <div class="grid">
              <p><span class="font-bold">{{ $t('column.occurrence_percentage') }}</span> - {{ data.percentage }}%</p>
              <p><span class="font-bold">{{ $t('column.fa') }}</span> - {{ data.fa }}</p>
              <p><span class="font-bold">{{ $t('column.rpn') }}</span> - {{ data.rpn }}</p>
            </div>
          </div>
        </DialogDescription>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
