<script setup lang="ts">
  import {
    Dialog, DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
  } from '@/components/ui/dialog'
  import {Button} from "@/components/ui/button";
  import TablesService from "@/services/tables/tables.service";
  import useEventsBus from "@/utils/eventBus";

  const props = defineProps<{
    aId?: [],
    object?: object,
    entity: string,
    open: boolean
    setOpen: any
  }>()

  const { emit } = useEventsBus();

  async function deleteRecord(entity: string, id?: number) {
    if (id && entity === 'attachments') {
      await TablesService.deleteRecord(id, entity);
    }
    if (id) {
      emit('delete_'+props.entity, id);
    } else if (props.aId) {
      emit('delete_aDocuments', props.aId)
    } else if (props.object) {
      emit('delete_'+props.entity, props.object);
    }
  }
</script>

<template>
  <Dialog :open="props.open" @update:open="props.setOpen">
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{{ $t('table.delete') }}</DialogTitle>
        <DialogDescription>
          {{ $t('table.delete_description') }}
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <DialogClose as-child>
          <Button type="button" variant="secondary">
            {{ $t('table.cancel') }}
          </Button>
        </DialogClose>
        <DialogClose as-child>
          <Button @click="deleteRecord( props.entity, props.object?.id )" type="button" variant="destructive">
            {{ $t('table.confirm') }}
          </Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
