<script setup lang="ts">
  import DataTable from "@/components/ui_custom/data-table/DataTable.vue";
  import {onMounted, ref, watch} from "vue";
  import { columns } from "./columns"
  import TablesService from "@/services/tables/tables.service";
  import useEventsBus from "@/utils/eventBus";
  import {Building} from "@/models/tables/building.interface";
  import {i18n} from "@/utils/i18n";
  import {toast} from "@/utils/utils";
  import router from "@/router";

  const data = ref<Building[]>([]);
  const { bus } = useEventsBus();
  const entity = 'buildings';

  async function getData(): Promise<Building[]> {
    const res = await TablesService.getData(entity);
    return res?.data;
  }

  onMounted(async () => {
    data.value = await getData();
  })

  watch(() => bus.value.get('new_'+entity), async () => {
    await router.push({ name:'newBuilding', params: { id: 0 } });
  })

  watch(() => bus.value.get('delete_'+entity), async (value) => {
    await TablesService.deleteRecord(value, entity);
    data.value = data.value.filter(row => row.id !== value);
    toast.success(i18n.global.t('toast.delete_success'));
  })
</script>

<template>
  <div class="container py-10 mx-auto">
    <p class="font-bold text-xl">{{ $t('table.buildings_list') }}</p>
    <DataTable :columns="columns" :data="data" :entity="entity" />
  </div>
</template>

<style scoped>

</style>
