import { h } from 'vue'
import { ColumnDef } from "@tanstack/vue-table";
import DropdownAction from '@/components/tables/matrix/MatrixDataTableDropDown.vue'
import {i18n} from "@/utils/i18n";
import {Matrix} from "@/models/tables/matrix.interface";

export const columns: ColumnDef<Matrix>[] = [
    {
        accessorKey: 'id',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.id')),
        cell: ({ row }) => {
            const id = Number(row.getValue('id'));
            return h('div', { class: 'text-right font-medium' }, id)
        },
    },
    {
        accessorKey: 'r',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.r')),
        cell: ({ row }) => {
            const r = String(row.getValue('r'));
            return h('div', { class: 'text-right font-medium' }, r)
        },
    },
    {
        accessorKey: 'g',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.g')),
        cell: ({ row }) => {
            const g = String(row.getValue('g'));
            return h('div', { class: 'text-right font-medium' }, g)
        },
    },
    {
        accessorKey: 'group',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.group')),
        cell: ({ row }) => {
            const group = String(row.getValue('group'));
            return h('div', { class: 'text-right font-medium' }, group)
        },
    },
    {
        accessorKey: 'letter',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.letter')),
        cell: ({ row }) => {
            const letter = String(row.getValue('letter'));
            return h('div', { class: 'text-right font-medium' }, letter)
        },
    },
    {
        id: 'actions',
        cell: ({ row }) => {
            const object = row.original
            return h('div', { class: 'relative' }, h(DropdownAction, { object, entity: 'matrix' }))
        },
    },
]
