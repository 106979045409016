<script setup lang="ts">
  import {Button} from "@/components/ui/button";
  import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter, DialogHeader, DialogTitle
  } from "@/components/ui/dialog";
  import {Input} from "@/components/ui/input";
  import TablesService from "@/services/tables/tables.service";
  import useEventsBus from "@/utils/eventBus";
  import {Separator} from "@/components/ui/separator";
  import {toTypedSchema} from "@vee-validate/zod";
  import * as z from "zod";
  import {useForm} from "vee-validate";
  import {onMounted, watch} from "vue";
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from '@/components/ui/form'
  import {Textarea} from "@/components/ui/textarea";

  const props = defineProps<{
    object: any,
    entity: string,
    open: boolean,
    setOpen: any,
  }>()
  const { emit } = useEventsBus();
  let defect: any = {};
  const formSchema = toTypedSchema(z.object({
    code: z.string(),
    description: z.string(),
    detectability: z.number(),
    gravity: z.number(),
  }))

  const { handleSubmit, values, setValues } = useForm({
    validationSchema: formSchema,
  })

  watch(values, (newFormData) => {
    defect = newFormData;
  });

  const onSubmit = handleSubmit(async () => {
    let res;
    if (!props.object.id) {
      res = await TablesService.createRecord(defect, props.entity);
    } else {
      res = await TablesService.updateRecord(defect, props.entity);
    }
    if (res != undefined) {
      emit('update_'+props.entity, res.data);
    }
  })

  watch(() => props.open, async(_, newValue) => {
    if (newValue === false) {
      if (props.object.id) {
        await initializeForm()
      }
    }
  },{ immediate: true })

  async function initializeForm() {
    const res = await TablesService.getElementById(props.entity, props.object.id);
    if (res?.data) {
      setValues(res.data);
    }
  }
</script>

<template>
  <Dialog :open="props.open" @update:open="props.setOpen">
    <DialogContent class="max-w-[60vw]">
      <DialogHeader>
        <DialogTitle v-if="!props.object.id">{{ $t('table.add') }}</DialogTitle>
        <DialogTitle v-else>{{ $t('table.edit') }}</DialogTitle>
        <form @submit="onSubmit" class="mt-3">
          <FormField v-slot="{ componentField }" name="code">
            <FormItem class="w-full">
              <FormLabel>{{ $t('column.code') }}</FormLabel>
              <FormControl>
                <Input type="text" :placeholder="$t('column.code')" v-bind="componentField" auto-correct="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="description">
            <FormItem class="w-full">
              <FormLabel>{{ $t('column.description') }}</FormLabel>
              <FormControl>
                <Textarea :placeholder="$t('column.description')" v-bind="componentField"></Textarea>
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <Separator class="my-1" />
          <div class="flex gap-4">
            <FormField v-slot="{ componentField }" name="detectability">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.detectability') }}</FormLabel>
                <FormControl>
                  <Input type="number" :placeholder="$t('column.detectability')" v-bind="componentField" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="gravity">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.gravity') }}</FormLabel>
                <FormControl>
                  <Input type="number" :placeholder="$t('column.gravity')" v-bind="componentField" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
          <DialogFooter class="mt-6">
            <DialogClose as-child>
              <Button type="button" variant="secondary">
                {{ $t('table.cancel') }}
              </Button>
            </DialogClose>
            <Button type="submit">
              {{ $t('table.confirm') }}
            </Button>
          </DialogFooter>
        </form>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
