import { h } from 'vue'
import { ColumnDef } from "@tanstack/vue-table";
import DropdownAction from '@/components/tables/defects/DefectsDataTableDropDown.vue'
import {i18n} from "@/utils/i18n";
import {Defect} from "@/models/tables/defect.interface";

export const columns: ColumnDef<Defect>[] = [
    {
        accessorKey: 'id',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.id')),
        cell: ({ row }) => {
            const id = Number(row.getValue('id'));
            return h('div', { class: 'text-right font-medium' }, id)
        },
    },
    {
        accessorKey: 'code',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.code')),
        cell: ({ row }) => {
            const code = String(row.getValue('code'));
            return h('div', { class: 'text-right font-medium' }, code)
        },
    },
    {
        accessorKey: 'detectability',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.detectability')),
        cell: ({ row }) => {
            const detectability = String(row.getValue('detectability'));
            return h('div', { class: 'text-right font-medium' }, detectability)
        },
    },
    {
        accessorKey: 'gravity',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.gravity')),
        cell: ({ row }) => {
            const gravity = String(row.getValue('gravity'));
            return h('div', { class: 'text-right font-medium' }, gravity)
        },
    },
    {
        accessorKey: 'description',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.description')),
        cell: ({ row }) => {
            const description = String(row.getValue('description'));
            return h('div', { class: 'text-right font-medium' }, description)
        },
    },
    {
        id: 'actions',
        cell: ({ row }) => {
            const object = row.original
            return h('div', { class: 'relative' }, h(DropdownAction, { object, entity: 'defects' }))
        },
    },
]
