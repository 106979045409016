<script setup lang="ts">
import {Carousel, CarouselApi, CarouselContent, CarouselItem} from "@/components/ui/carousel";
import {Card, CardContent} from "@/components/ui/card";
import {onMounted, ref} from "vue";
import TablesService from "@/services/tables/tables.service";
import {watchOnce} from "@vueuse/core";

const props = defineProps<{
  entityId: any
  entity: string
}>()
const photos: any = ref([]);
const emblaMainApi = ref<CarouselApi>()
const emblaThumbnailApi = ref<CarouselApi>()
const selectedIndex = ref(0)

function onSelect() {
  if (!emblaMainApi.value || !emblaThumbnailApi.value)
    return
  selectedIndex.value = emblaMainApi.value.selectedScrollSnap()
  emblaThumbnailApi.value.scrollTo(emblaMainApi.value.selectedScrollSnap())
}

function onThumbClick(index: number) {
  if (!emblaMainApi.value || !emblaThumbnailApi.value)
    return
  emblaMainApi.value.scrollTo(index)
}

watchOnce(emblaMainApi, (emblaMainApi) => {
  if (!emblaMainApi)
    return

  onSelect()
  emblaMainApi.on('select', onSelect)
  emblaMainApi.on('reInit', onSelect)
})

onMounted(async () => {
  const res = await TablesService.getAttachments(props.entity, props.entityId, 'photos');
  photos.value = res?.data
})
</script>

<template>
  <div class="w-full grid justify-center justify-items-center">
    <Carousel
        class="relative w-full max-w-sm h-auto"
        @init-api="(val) => emblaMainApi = val"
    >
      <CarouselContent>
        <CarouselItem class="cursor-pointer" v-for="photo in photos" :key="photo.id">
          <div>
            <Card>
              <CardContent class="flex aspect-square items-center justify-center p-0">
                <img :src="`data:${photo.mimeType};base64, ${photo.data}`" :alt="photo.filename" class="h-[100%] w-[100%] object-cover rounded-lg">
              </CardContent>
            </Card>
          </div>
        </CarouselItem>
      </CarouselContent>
    </Carousel>

    <Carousel
        class="relative w-full max-w-sm"
        @init-api="(val) => emblaThumbnailApi = val"
    >
      <CarouselContent class="flex gap-1 ml-0">
        <CarouselItem v-for="(photo, index) in photos" :key="photo.id" class="pl-0 basis-1/4 cursor-pointer" @click="onThumbClick(index)">
          <div class="p-1" :class="index === selectedIndex ? '' : 'opacity-50'">
            <Card>
              <CardContent class="flex aspect-square items-center justify-center p-0">
                <img :src="`data:${photo.mimeType};base64, ${photo.data}`" :alt="photo.filename" class="h-[100%] w-[100%] object-cover rounded-lg">
              </CardContent>
            </Card>
          </div>
        </CarouselItem>
      </CarouselContent>
    </Carousel>
  </div>
</template>

<style scoped>

</style>
