<script setup lang="ts">
  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from '@/components/ui/table'
  import { Roof } from "@/models/tables/roof.interface"
  import {Image, ImagePlus, MoreHorizontal, Pencil, Trash2, LineChart, FilePlus, File} from "lucide-vue-next";
  import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger
  } from "@/components/ui/dropdown-menu";
  import {Button} from "@/components/ui/button";
  import {ref, watch} from "vue";
  import useEventsBus from "@/utils/eventBus";
  import DeleteDialog from "@/components/tables/DeleteDialog.vue";
  import router from "@/router";
  import UploadDocumentDialog from "@/components/building/UploadDocumentDialog.vue";

  const props = defineProps<{
    roofs: Roof[],
    entity: string
  }>()
  const isDeleteDialogOpen = ref(false);
  const isDocumentDialogOpen = ref(false);

  const { bus } = useEventsBus();

  const setIsDeleteDialogOpen = (value: boolean) => {
    isDeleteDialogOpen.value = value;
  };

  const setIsDocumentDialogOpen = (value: boolean) => {
    isDocumentDialogOpen.value = value;
  };

  watch(() => bus.value.get('update_'+props.entity), async () => {
    setIsDeleteDialogOpen(false);
  })
</script>

<template>
  <h3 class="font-semibold mt-6">{{$t('building.roofs_list')}}</h3>
  <Table>
    <TableHeader class="bg-gray-300 font-black">
      <TableRow>
        <TableHead>{{$t('column.name')}}</TableHead>
        <TableHead>{{$t('column.code')}}</TableHead>
        <TableHead class="text-right">{{$t('table.actions')}}</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      <TableRow v-for="roof in props.roofs" :key="roof.code">
        <TableCell>{{roof.name}}</TableCell>
        <TableCell>{{roof.code}}</TableCell>
        <TableCell class="grid justify-end">
          <DropdownMenu>
            <DropdownMenuTrigger as-child>
              <Button variant="ghost" class="w-8 h-8 p-0">
                <MoreHorizontal class="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>{{ $t('table.actions') }}</DropdownMenuLabel>
              <DropdownMenuItem @click.prevent="$emit('edit', roof)" class="cursor-pointer">
                <Pencil class="mr-2 h-4 w-4" />
                {{ $t('table.edit') }}
              </DropdownMenuItem>
              <DropdownMenuItem @click.prevent="router.push({ name: 'camera', params: { id: roof.id, entity: 'coverages' }})" class="cursor-pointer">
                <ImagePlus class="mr-2 h-4 w-4" />
                {{ $t('building.add_photo') }}
              </DropdownMenuItem>
              <DropdownMenuItem @click.prevent="router.push({ name: 'photoRoof', params: { entity:'coverages', id: roof.id } })" class="cursor-pointer">
                <Image class="mr-2 h-4 w-4" />
                {{ $t('building.gallery') }}
              </DropdownMenuItem>
              <DropdownMenuItem @click.prevent="setIsDocumentDialogOpen(true)" :disabled="!roof.id" class="cursor-pointer">
                <FilePlus class="mr-2 h-4 w-4" />
                {{ $t('building.add_document') }}
              </DropdownMenuItem>
              <DropdownMenuItem @click.prevent="router.push({ name: 'documentsView', params: { entity:'coverages', id: roof.id } })" :disabled="!roof.id" class="cursor-pointer">
                <File class="mr-2 h-4 w-4" />
                {{ $t('building.document_gallery') }}
              </DropdownMenuItem>
              <DropdownMenuItem @click.prevent="router.push({ name: 'rci', params: { id: roof.id }})" class="cursor-pointer">
                <LineChart class="mr-2 h-4 w-4" />
                {{ $t('table.rci') }}
              </DropdownMenuItem>
              <DropdownMenuItem @click.prevent="setIsDeleteDialogOpen(true)" class="cursor-pointer">
                <Trash2 class="mr-2 h-4 w-4" />
                {{ $t('table.delete') }}
              </DropdownMenuItem>
            </DropdownMenuContent>
            <DeleteDialog :entity="props.entity" :object="roof" :open="isDeleteDialogOpen" :set-open="setIsDeleteDialogOpen" />
            <UploadDocumentDialog :entity="props.entity" :entity-id="roof.id" :open="isDocumentDialogOpen" :set-open="setIsDocumentDialogOpen" @edit="setIsDocumentDialogOpen(false)" />
          </DropdownMenu>
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
</template>

<style scoped>

</style>
