import { h } from 'vue'
import { ColumnDef } from "@tanstack/vue-table";
import { i18n } from "@/utils/i18n";
import {Building} from "@/models/tables/building.interface";

export const buildingColumns: ColumnDef<Building>[] = [
    {
        accessorKey: 'id',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.id')),
        cell: ({ row }) => {
            const id = Number(row.getValue('id'));
            return h('div', { class: 'text-right font-medium' }, id)
        },
    },
    {
        accessorKey: 'name',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.name')),
        cell: ({ row }) => {
            const name = String(row.getValue('name'));
            return h('div', { class: 'text-right font-medium' }, name)
        },
    },
    {
        accessorKey: 'address',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.address')),
        cell: ({ row }) => {
            const address = String(row.original.address);
            const city = String(row.original.city);
            const zip = String(row.original.zip);
            const province = String(row.original.province);
            const state = String(row.original.state);
            const formattedAddress = address+' '+zip+' '+city+' ('+province+') '+state;
            return h('div', { class: 'text-right font-medium' }, formattedAddress)
        },
    },
]
