<script setup lang="ts">
  import {Button} from "@/components/ui/button";
  import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter, DialogHeader, DialogTitle
  } from "@/components/ui/dialog";
  import {Input} from "@/components/ui/input";
  import TablesService from "@/services/tables/tables.service";
  import {Switch} from "@/components/ui/switch";
  import useEventsBus from "@/utils/eventBus";
  import {Separator} from "@/components/ui/separator";
  import {toTypedSchema} from "@vee-validate/zod";
  import * as z from "zod";
  import {useForm} from "vee-validate";
  import {onMounted, watch} from "vue";
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from '@/components/ui/form'

  const props = defineProps<{
    object: any,
    entity: string,
    open: boolean,
    setOpen: any,
  }>()
  const { emit } = useEventsBus();
  let master: any = {};
  const formSchema = toTypedSchema(z.object({
    name: z.string(),
    code: z.string(),
    address: z.string(),
    city: z.string(),
    zip: z.string(),
    province: z.string(),
    state: z.string(),
    enabled: z.boolean().default(true)
  }))
  const { handleSubmit, values, setValues } = useForm({
    validationSchema: formSchema,
  })

  watch(values, (newFormData) => {
    master = newFormData;
  });

  const onSubmit = handleSubmit(async () => {
    let res;
    if (!props.object.id) {
      res = await TablesService.createRecord(master, props.entity);
    } else {
      res = await TablesService.updateRecord(master, props.entity);
    }
    if (res != undefined) {
      emit('update_'+props.entity, res.data);
    }
  })

  watch(() => props.open, async(_, newValue) => {
    if (newValue === false) {
      if (props.object.id) {
        setValues(props.object);
      }
    }
  },{ immediate: true })
</script>

<template>
  <Dialog :open="props.open" @update:open="props.setOpen">
    <DialogContent class="max-w-[60vw]">
      <DialogHeader>
        <DialogTitle v-if="!props.object.id">{{ $t('table.add') }}</DialogTitle>
        <DialogTitle v-else>{{ $t('table.edit') }}</DialogTitle>
        <form @submit="onSubmit" class="mt-3">
          <FormField v-slot="{ componentField }" name="code">
            <FormItem class="w-full">
              <FormLabel>{{ $t('column.code') }}</FormLabel>
              <FormControl>
                <Input type="text" :placeholder="$t('column.code')" v-bind="componentField" auto-correct="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="name">
            <FormItem class="w-full">
              <FormLabel>{{ $t('column.name') }}</FormLabel>
              <FormControl>
                <Input type="text" :placeholder="$t('column.name')" v-bind="componentField" auto-correct="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <Separator class="my-1" />
          <div class="flex gap-4">
            <FormField v-slot="{ componentField }" name="address">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.address') }}</FormLabel>
                <FormControl>
                  <Input type="text" :placeholder="$t('column.address')" v-bind="componentField" auto-correct="off" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="city">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.city') }}</FormLabel>
                <FormControl>
                  <Input type="text" :placeholder="$t('column.city')" v-bind="componentField" auto-correct="off" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
          <div class="flex gap-4">
            <FormField v-slot="{ componentField }" name="zip">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.zip') }}</FormLabel>
                <FormControl>
                  <Input type="text" :placeholder="$t('column.zip')" v-bind="componentField" auto-correct="off" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="province">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.province') }}</FormLabel>
                <FormControl>
                  <Input type="text" :placeholder="$t('column.province')" v-bind="componentField" auto-correct="off" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="state">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.state') }}</FormLabel>
                <FormControl>
                  <Input type="text" :placeholder="$t('column.state')" v-bind="componentField" auto-correct="off" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
          <Separator class="my-1" />
          <FormField v-slot="{ value, handleChange }" name="enabled">
            <FormItem class="flex flex-row items-center gap-4 p-4">
              <div class="space-y-0.5">
                <FormLabel class="text-base">
                  {{ $t('table.enabled') }}
                </FormLabel>
              </div>
              <FormControl>
                <Switch :checked="value" @update:checked="handleChange" />
              </FormControl>
            </FormItem>
          </FormField>
          <DialogFooter>
            <DialogClose as-child>
              <Button type="button" variant="secondary">
                {{ $t('table.cancel') }}
              </Button>
            </DialogClose>
            <Button type="submit">
              {{ $t('table.confirm') }}
            </Button>
          </DialogFooter>
        </form>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
