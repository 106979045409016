import axios from "axios";
import {toast} from "@/utils/utils";

export default class SigninService {
    static async signIn(email: string, password: string) {
        try {
            return await axios.post(process.env.VUE_APP_BASE_API_URL + 'users/signin', {
                email: email,
                password: password
            }, {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e) {
            toast.error(e as string);
        }
    }

    static async forgotPassword(email: string) {
        try {
            return await axios.get(process.env.VUE_APP_BASE_API_URL + 'users/forgot?email='+email, {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e) {
            toast.error(e as string);
        }
    }

    static async resetPassword(password: string, email: string, newPasswordToken: string) {
        try {
            return await axios.post(process.env.VUE_APP_BASE_API_URL + 'users/reset', {
                email: email,
                newPassword: password,
                newPasswordToken: newPasswordToken
            }, {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e) {
            toast.error(e as string);
        }
    }
}
