import { h } from 'vue'
import { ColumnDef } from "@tanstack/vue-table";
import {i18n} from "@/utils/i18n";
import {Manager} from "@/models/tables/manager.interface";

export const managerColumns: ColumnDef<Manager>[] = [
    {
        accessorKey: 'id',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.id')),
        cell: ({ row }) => {
            const id = Number(row.getValue('id'));
            return h('div', { class: 'text-right font-medium' }, id)
        },
    },
    {
        accessorKey: 'name',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.name')),
        cell: ({ row }) => {
            const name = String(row.getValue('name'));
            return h('div', { class: 'text-right font-medium' }, name)
        },
    },
]
