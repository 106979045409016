<script setup lang="ts">
  import {Plus} from "lucide-vue-next";
  import {useRoofStore} from "@/store/roof.store";
  import {ref, watch} from "vue";
  import RoofsTable from "@/components/building/RoofsTable.vue";
  import {Button} from "@/components/ui/button";
  import {FormControl, FormField, FormItem, FormLabel, FormMessage} from "@/components/ui/form";
  import {Separator} from "@/components/ui/separator";
  import {Input} from "@/components/ui/input";
  import {Textarea} from "@/components/ui/textarea";
  import {useForm} from "vee-validate";
  import {toTypedSchema} from "@vee-validate/zod";
  import * as z from "zod";
  import {storeToRefs} from "pinia";
  import TablesService from "@/services/tables/tables.service";
  import {i18n} from "@/utils/i18n";
  import {toast} from "@/utils/utils";
  import useEventsBus from "@/utils/eventBus";
  import ElementsModal from "@/components/building/ElementsModal.vue";
  import ElementsTable from "@/components/building/ElementsTable.vue";

  const props = defineProps<{
    id: any,
    entity: string
  }>()
  const roofStore = useRoofStore();
  const roofForm = ref(false);
  const isElementDialogOpen = ref(false);
  const { bus } = useEventsBus();
  let { roof_list } = storeToRefs(roofStore);
  let { element_list } = storeToRefs(roofStore);

  const roofSchema = toTypedSchema(z.object({
    name: z.string(),
    code: z.string(),
    description: z.string(),
    estimatedLife: z.number(),
    installationYear: z.number(),
    dimensions: z.number(),
    slope: z.number(),
    constructionCost: z.number(),
    interventionCost: z.number(),
  }))
  const addButton = ref(true);

  const setIsElementDialogOpen = (value: boolean) => {
    isElementDialogOpen.value = value;
  };

  const { handleSubmit, setValues } = useForm({
    validationSchema: roofSchema
  })

  watch(() => bus.value.get('delete_'+props.entity), async (value) => {
    await deleteRoof(value);
  })

  async function deleteRoof(id: any) {
    const res = await TablesService.deleteRecord(id, props.entity);
    if (res?.data) {
      toast.success(i18n.global.t('toast.delete_success'));
    }
  }

  async function addElement(element: any) {
    element_list.value = await roofStore.addElementToList(element);
    setIsElementDialogOpen(false);
  }

  async function deleteElement(element: any) {
    element_list.value = await roofStore.removeElementFromList(element);
    toast.success(i18n.global.t('toast.delete_success'));
  }

  async function editRoof(roof: any) {
    addButton.value = false;
    const roofInStore = await roofStore.setRoof(roof);
    element_list.value = roof.coverageElements;
    setValues(roofInStore);
    roofForm.value = true;
  }

  const addRoof = handleSubmit(async (values: any) => {
    let res;
    let roofInStore = await roofStore.getRoof() as any;
    values.building = props.id;
    Object.assign(roofInStore, values);
    roofInStore.coverageElements = element_list.value;
    if (roofInStore.id) {
      res = await TablesService.updateRecord(roofInStore, props.entity);
      if (res?.data) {
        roofStore.clearRoof();
        toast.success(i18n.global.t('toast.edit_success'));
        roofForm.value = false;
      }
    } else {
      res = await TablesService.createRecord(roofInStore, props.entity);
      if (res?.data) {
        roofInStore.id = res.data.id
        await roofStore.addRoofToList(roofInStore);
        roofStore.clearRoof();
        toast.success(i18n.global.t('toast.insert_success'));
        roofForm.value = false;
      }
    }
    addButton.value = true;
  })
</script>

<template>
  <Button @click.prevent="roofForm = true; addButton = false;" v-if="addButton" type="button" class="w-full text-center">
    <Plus class="mr-2 h-5 w-5" />
    {{ $t('building.add_roof') }}
  </Button>
  <form v-if="roofForm" @submit="addRoof">
    <div class="flex gap-4">
      <FormField v-slot="{ componentField }" name="name">
        <FormItem class="w-full">
          <FormLabel>{{ $t('column.name') }}</FormLabel>
          <FormControl>
            <Input
                v-bind="componentField"
                :placeholder="$t('column.name')"
                type="text"
                auto-correct="off"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <FormField v-slot="{ componentField }" name="code">
        <FormItem class="w-full">
          <FormLabel>{{ $t('column.code') }}</FormLabel>
          <FormControl>
            <Input
                v-bind="componentField"
                :placeholder="$t('column.code')"
                type="text"
                auto-correct="off"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
    </div>
    <FormField v-slot="{ componentField }" name="description">
      <FormItem class="w-full">
        <FormLabel>{{ $t('column.description') }}</FormLabel>
        <FormControl>
            <Textarea
                v-bind="componentField"
                id="description"
                :placeholder="$t('column.description')"
            />
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormField>
    <Separator class="mt-3" />
    <div class="w-full bg-gray-400 p-2">
      <h3 class="text-lg font-semibold">{{ $t('building.generality') }}</h3>
    </div>
    <div class="flex gap-4">
      <FormField v-slot="{ componentField }" name="estimatedLife">
        <FormItem class="w-full">
          <FormLabel>{{ $t('column.estimated_life') }}</FormLabel>
          <FormControl>
            <Input
                v-bind="componentField"
                :placeholder="$t('column.estimated_life')"
                type="number"
                auto-correct="off"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <FormField v-slot="{ componentField }" name="installationYear">
        <FormItem class="w-full">
          <FormLabel>{{ $t('column.installation_year') }}</FormLabel>
          <FormControl>
            <Input
                v-bind="componentField"
                :placeholder="$t('column.installation_year')"
                type="number"
                auto-correct="off"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <FormField v-slot="{ componentField }" name="dimensions">
        <FormItem class="w-full">
          <FormLabel>{{ $t('column.dimensions') }}</FormLabel>
          <FormControl>
            <Input
                v-bind="componentField"
                :placeholder="$t('column.dimensions')"
                type="number"
                auto-correct="off"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
    </div>
    <div class="flex gap-4">
      <FormField v-slot="{ componentField }" name="slope">
        <FormItem class="w-full">
          <FormLabel>{{ $t('column.slope') }}</FormLabel>
          <FormControl>
            <Input
                v-bind="componentField"
                :placeholder="$t('column.slope')"
                type="number"
                auto-correct="off"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <FormField v-slot="{ componentField }" name="constructionCost">
        <FormItem class="w-full">
          <FormLabel>{{ $t('column.construction_cost') }}</FormLabel>
          <FormControl>
            <Input
                v-bind="componentField"
                :placeholder="$t('column.construction_cost')"
                type="number"
                auto-correct="off"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
      <FormField v-slot="{ componentField }" name="interventionCost">
        <FormItem class="w-full">
          <FormLabel>{{ $t('column.intervention_cost') }}</FormLabel>
          <FormControl>
            <Input
                v-bind="componentField"
                :placeholder="$t('column.intervention_cost')"
                type="number"
                auto-correct="off"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>
    </div>
    <Separator class="my-3" />
    <div class="w-full bg-gray-400 p-2">
      <h3 class="text-lg font-semibold">{{ $t('menu.coverage_elements') }}</h3>
    </div>
    <Button @click.prevent="setIsElementDialogOpen(true)" type="button" class="w-full text-center">
      <Plus class="mr-2 h-5 w-5" />
      {{ $t('building.add_element') }}
    </Button>
    <ElementsModal :open="isElementDialogOpen" :set-open="setIsElementDialogOpen" @add="addElement" />
    <ElementsTable v-if="element_list.length !== 0" :elements="element_list" entity="defects" @delete="deleteElement" />
    <Separator class="my-6" />
    <div class="flex gap-4">
      <Button @click.prevent="roofForm = false; roofStore.clearRoof(); addButton = true" type="button" class="w-full">
        {{ $t('table.cancel') }}
      </Button>
      <Button @click.prevent="addRoof" type="button" class="w-full">
        {{ $t('table.save') }}
      </Button>
    </div>
  </form>
  <RoofsTable v-if="roof_list.length !== 0" :roofs="roof_list" entity="coverages" @edit="editRoof" @delete="deleteRoof" />
</template>

<style scoped>

</style>
