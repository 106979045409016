<script setup lang="ts">
  import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/card";
  import DashboardDataTable from "@/components/ui_custom/data-table/DashboardDataTable.vue";
  import {onMounted, onUnmounted, ref} from "vue";
  import SharedService from "@/services/shared/shared.service";
  import { buildingColumns } from "@/components/tables/buildings/dashboard-columns"
  import { userColumns } from "@/components/tables/users/dashboard-columns"
  import { masterColumns } from "@/components/tables/masters/dashboard-columns"
  import { managerColumns } from "@/components/tables/managers/dashboard-columns"
  import {User} from "@/models/tables/user.interface";
  import {Building} from "@/models/tables/building.interface";
  import {Master} from "@/models/tables/master.interface";
  import {Manager} from "@/models/tables/manager.interface";
  import { ExternalLink } from "lucide-vue-next";

  let dashboard: any = ref({});
  let users = ref<User[]>([]);
  let buildings = ref<Building[]>([]);
  let masters = ref<Master[]>([]);
  let managers = ref<Manager[]>([]);



  onMounted(async () => {
    const res = await SharedService.dashboard();
    dashboard.value = res?.data;
    buildings.value = dashboard.value.buildings;
    users.value = dashboard.value.users;
    masters.value = dashboard.value.masters;
    managers.value = dashboard.value.managers;
  })
</script>

<template>
  <div class="flex w-5/6">
    <div class="container py-10 mx-auto w-full">
      <Card class="w-full">
        <CardHeader class="relative bg-gray-400 cursor-pointer" @click.prevent="$router.push({ name: 'buildingsTable' })">
          <CardTitle>
              <div class="absolute top-0 left-0 text-lg ml-2 flex items-center">
                <ExternalLink class="mr-2 h-5 w-5" />
                {{ $t('menu.buildings') }}
              </div>
              <div class="absolute top-0 right-0 text-lg font-semibold mr-2">{{ $t('dashboard.total')}}: {{ dashboard.buildingsNumber }}</div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <DashboardDataTable :data="buildings" :columns="buildingColumns" entity="buildings" />
        </CardContent>
      </Card>
      <div class="lg:flex md:flex-none lg:gap-10 md:gap-0 lg:my-10 md:my-0">
        <Card class="w-full">
          <CardHeader class="relative bg-gray-400 cursor-pointer" @click.prevent="$router.push({ name: 'managersTable' })">
            <CardTitle>
              <div class="absolute top-0 text-lg left-0 ml-2 flex items-center">
                <ExternalLink class="mr-2 h-5 w-5" />
                {{ $t('menu.managers') }}
              </div>
              <div class="absolute top-0 text-lg right-0 font-semibold mr-2">{{ $t('dashboard.total')}}: {{ dashboard.managersNumber }}</div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <DashboardDataTable :data="managers" :columns="managerColumns" entity="managers" />
          </CardContent>
        </Card>
        <Card class="w-full">
          <CardHeader class="relative bg-gray-400 cursor-pointer" @click.prevent="$router.push({ name: 'mastersTable' })">
            <CardTitle>
              <div class="absolute top-0 text-lg left-0 ml-2 flex items-center">
                <ExternalLink class="mr-2 h-5 w-5" />
                {{ $t('menu.masters') }}
              </div>
              <div class="absolute top-0 text-lg right-0 font-semibold mr-2">{{ $t('dashboard.total')}}: {{ dashboard.mastersNumber }}</div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <DashboardDataTable :data="masters" :columns="masterColumns" entity="masters" />
          </CardContent>
        </Card>
      </div>
      <Card class="w-full">
        <CardHeader class="relative bg-gray-400 cursor-pointer" @click.prevent="$router.push({ name: 'usersTable' })">
          <CardTitle>
            <div class="absolute top-0 text-lg left-0 ml-2 flex items-center">
              <ExternalLink class="mr-2 h-5 w-5" />
              {{ $t('menu.users') }}
            </div>
            <div class="absolute top-0 text-lg right-0 font-semibold mr-2">{{ $t('dashboard.total')}}: {{ dashboard.usersNumber }}</div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <DashboardDataTable :data="users" :columns="userColumns" entity="users" />
        </CardContent>
      </Card>
    </div>
  </div>
</template>

