import { h } from 'vue'
import { ColumnDef } from "@tanstack/vue-table";
import DropdownAction from '@/components/tables/service_orders/ServiceOrdersDataTableDropDown.vue'
import { i18n } from "@/utils/i18n";
import { ServiceOrder } from "@/models/tables/serviceOrder.interface";

export const columns: ColumnDef<ServiceOrder>[] = [
    {
        accessorKey: 'id',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.id')),
        cell: ({ row }) => {
            const id = Number(row.getValue('id'));
            return h('div', { class: 'text-right font-medium' }, id)
        },
    },
    {
        accessorKey: 'date',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.date')),
        cell: ({ row }) => {
            const date = new Date(row.getValue('date')).toLocaleDateString();
            return h('div', { class: 'text-right font-medium' }, date)
        },
    },
    {
        accessorKey: 'user',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.operator')),
        cell: ({ row }) => {
            const operator: any = row.original.user;
            const fullName = operator.name + ' ' + operator.surname
            return h('div', { class: 'text-right font-medium' }, fullName)
        },
    },
    {
        accessorKey: 'coverage',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.coverage')),
        cell: ({ row }) => {
            const coverage: any = row.original.coverage;
            return h('div', { class: 'text-right font-medium' }, coverage.name)
        },
    },
    {
        id: 'actions',
        cell: ({ row }) => {
            const object = row.original
            object.coverage = row.getValue('coverage');
            return h('div', { class: 'relative' }, h(DropdownAction, { object, entity: 'service-order' }))
        },
    },
]
