<script setup lang="ts">
  import {Button} from "@/components/ui/button";
  import {Plus} from "lucide-vue-next";
  import CategoriesEditForm from "@/components/tables/categories/CategoriesEditForm.vue";
  import {onMounted, ref, watch} from "vue";
  import axios from "axios";
  import TablesService from "@/services/tables/tables.service";
  import {toast} from "@/utils/utils";
  import {i18n} from "@/utils/i18n";
  import useEventsBus from "@/utils/eventBus";
  import {Category} from "@/models/tables/category.interface";
  import CategoriesDataTableDropDown from "@/components/tables/categories/CategoriesDataTableDropDown.vue";

  const isAddDialogOpen = ref(false);
  const entity = 'categories';
  const categories = ref<Category[]>([]);
  const flattenedCategories = ref([]);
  const { bus } = useEventsBus();

  const fetchCategories = async () => {
    const res = await axios.get(process.env.VUE_APP_BASE_API_URL + 'categories/tree', {
      headers: {
        Authorization: process.env.VUE_APP_API_KEY
      }
    });
    categories.value = res.data;
    flattenedCategories.value = flattenCategories(res.data);
  };

  function flattenCategories(categories: any, level = 0): any {
    let result = [];
    for (const category of categories) {
      let flatCategory = {
        ...category,
        indentedName: `${'. '.repeat(level)}${category.name}`,
        level: level
      };
      result.push(flatCategory);
      if (category.children && category.children.length) {
        result = result.concat(flattenCategories(category.children, level + 1));
      }
    }
    return result;
  }

  const setIsAddDialogOpen = (value: boolean) => {
    isAddDialogOpen.value = value;
  };

  onMounted(() => {
    fetchCategories();
  });

  watch(() => bus.value.get('delete_'+entity), async (value) => {
    await TablesService.deleteRecord(value, entity);
    await fetchCategories();
    toast.success(i18n.global.t('toast.delete_success'));
  })

  watch(() => bus.value.get('update_'+entity), async () => {
    await fetchCategories();
    setIsAddDialogOpen(false);
    toast.success(i18n.global.t('toast.edit_success'));
  })
</script>

<template>
  <div class="container py-10 mx-auto">
    <v-container class="p-0 m-0">
      <div class="flex items-center justify-between">
        <p class="font-bold text-xl">{{ $t('table.categories_list') }}</p>
        <Button color="primary" class="ml-auto" @click="setIsAddDialogOpen(true)">
          <Plus class="mr-2 h-5 w-5" />
          {{ $t('table.add') }}
        </Button>
      </div>

      <v-treeview
          :items="categories"
          activatable
      >
        <template v-slot:prepend>
          <label>
            <v-icon class="mr-2">
              {{ 'mdi-folder' }}
            </v-icon>
          </label>
        </template>
        <template v-slot:append="{ item }">
          <CategoriesDataTableDropDown :entity="entity" :object="item" :parents="flattenedCategories"/>
        </template>
      </v-treeview>
      <CategoriesEditForm :set-open="setIsAddDialogOpen" :entity="entity" :open="isAddDialogOpen" object="" :parents="flattenedCategories" />
    </v-container>
  </div>
</template>

<style scoped>

</style>
