<script setup lang="ts">
import {Carousel, CarouselContent, CarouselItem} from "@/components/ui/carousel";
import {Card, CardContent} from "@/components/ui/card";
import {onMounted, ref} from "vue";
import TablesService from "@/services/tables/tables.service";

const props = defineProps<{
  data: any
  entity: string
}>()
const roofs: any = ref([]);

onMounted(async () => {
  if (props.data) {
    for (const roof of props.data) {
      const res = await TablesService.getAttachments(props.entity, roof.id, 'photos');
      roof.attachments = res?.data;
      roofs.value.push(roof);
    }
  }
})
</script>

<template>
  <Carousel
      class="relative w-[70%] h-full pl-4"
      :opts="{
      align: 'start',
    }"
  >
    <CarouselContent>
      <CarouselItem v-for="roof in roofs" :key="roof.id" class="md:basis-1/2 lg:basis-1/3">
        <div class="p-1 cursor-pointer min-w-lg" @click.prevent="$router.push({ name: 'viewRoof', params: { id: roof.id } })">
          <Card>
            <CardContent class="flex aspect-square items-center justify-center p-0">
              <img :src="`data:${roof.attachments[0]?.mimeType};base64, ${roof.attachments[0]?.data}`" :alt="roof.attachments[0]?.filename" class="h-[100%] w-[100%] object-cover rounded-lg">
            </CardContent>
          </Card>
          <p class="font-bold">{{ roof.name }}</p>
        </div>
      </CarouselItem>
    </CarouselContent>
  </Carousel>
</template>

<style scoped>

</style>
