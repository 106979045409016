<script setup lang="ts">
  import { Button } from '@/components/ui/button'
  import {Check, ChevronsUpDown} from 'lucide-vue-next'
  import {onMounted, ref} from 'vue'
  import { useForm } from 'vee-validate'
  import { toTypedSchema } from '@vee-validate/zod'
  import * as z from 'zod'
  import {
    Dialog, DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
  } from '@/components/ui/dialog'
  import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
  } from '@/components/ui/command'
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from '@/components/ui/form'
  import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from '@/components/ui/popover'
  import TablesService from "@/services/tables/tables.service";
  import {cn} from "@/utils/utils";
  import {i18n} from "@/utils/i18n";

  const props = defineProps<{
    open: boolean
    setOpen: any
  }>()
  const emit = defineEmits(['add'])
  const elementsOpen = ref(false);
  let elements: any = [];

  const formSchema = toTypedSchema(z.object({
    id: z.any(),
    code: z.string(),
    description: z.string()
  }))

  const { handleSubmit, setValues, values } = useForm({
    validationSchema: formSchema,
  })

  const onSubmit = handleSubmit((values) => {
    emit('add', values);
  })

  async function getElements() {
    const res = await TablesService.getData('coverage-elements')
    elements = res?.data
  }

  onMounted(async () => {
    await getElements();
  })
</script>

<template>
  <Dialog :open="props.open" @update:open="props.setOpen">
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{{ $t('building.add_element') }}</DialogTitle>
      </DialogHeader>
      <form class="space-y-6" @submit="onSubmit">
        <FormField name="element">
          <FormItem class="flex flex-col w-full">
            <FormLabel>{{ $t('column.coverage_element') }}</FormLabel>
            <Popover v-model:open="elementsOpen">
              <PopoverTrigger as-child>
                <FormControl>
                  <Button
                      variant="outline"
                      role="combobox"
                      :class="cn('w-full justify-between', !values.id && 'text-muted-foreground')"
                  >
                    {{ values.id ? elements.find(
                      (element: any) => element.id === values.id,
                  )?.code : i18n.global.t('table.select') }}
                    <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent class="w-[45vw] p-0">
                <Command>
                  <CommandInput :placeholder="$t('table.search')" />
                  <CommandEmpty>{{$t('table.no_result')}}</CommandEmpty>
                  <CommandList>
                    <CommandGroup>
                      <CommandItem
                          v-for="element in elements"
                          :key="element.id"
                          :value="element.id"
                          @select="() => {
                                setValues({
                                  id: element.id,
                                  code: element.code,
                                  description: element.description
                                })
                                elementsOpen = false
                              }"
                      >
                        <Check
                            :class="cn('mr-2 h-4 w-4', element.id === values.id ? 'opacity-100' : 'opacity-0')"
                        />
                        {{ element.code }}
                      </CommandItem>
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        </FormField>
        <DialogFooter>
          <DialogClose as-child>
            <Button type="button" variant="secondary">
              {{ $t('table.cancel') }}
            </Button>
          </DialogClose>
          <Button type="submit">
            {{ $t('table.confirm') }}
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
