<script setup lang="ts">
  import DataTable from "@/components/ui_custom/data-table/DataTable.vue";
  import {onMounted, ref, watch} from "vue";
  import {columns} from "./columns"
  import TablesService from "@/services/tables/tables.service";
  import useEventsBus from "@/utils/eventBus";
  import {i18n} from "@/utils/i18n";
  import {toast} from "@/utils/utils";
  import {CoverageElement} from "@/models/tables/coverageElement.interface";
  import CoverageElementsEditForm from "@/components/tables/coverage_elements/CoverageElementsEditForm.vue";

  const data = ref<CoverageElement[]>([]);
  const { bus } = useEventsBus();
  const entity = 'coverage-elements';
  const isAddDialogOpen = ref(false);
  const object = {};

  async function getData(): Promise<CoverageElement[]> {
    const res = await TablesService.getData(entity);
    return res?.data;
  }

  const setIsAddDialogOpen = (value: boolean) => {
    isAddDialogOpen.value = value;
  };

  onMounted(async () => {
    data.value = await getData();
  })

  watch(() => bus.value.get('new_'+entity), async () => {
    setIsAddDialogOpen(true);
  })

  watch(() => bus.value.get('delete_'+entity), async (value) => {
    await TablesService.deleteRecord(value, entity);
    data.value = data.value.filter(row => row.id !== value);
    toast.success(i18n.global.t('toast.delete_success'));
  })

  watch(() => bus.value.get('update_'+entity), async (value) => {
    const object = data.value.some(obj => obj.id === value.id)
    if (!object) {
      data.value = [...data.value, value];
    } else {
      data.value = await getData();
    }
    setIsAddDialogOpen(false);
    toast.success(i18n.global.t('toast.edit_success'));
  })
</script>

<template>
  <div class="container py-10 mx-auto">
    <p class="font-bold text-xl">{{ $t('table.coverage_elements_list') }}</p>
    <DataTable :columns="columns" :data="data" :entity="entity" />
  </div>
  <CoverageElementsEditForm :entity="entity" :object="object" :open="isAddDialogOpen" :set-open="setIsAddDialogOpen" />
</template>

<style scoped>

</style>
