<script setup lang="ts">
  import {ref, watch} from "vue";
  import {useRoute} from "vue-router";
  import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger
  } from '@/components/ui/tabs'
  import BuildingForm from "@/components/building/form/BuildingForm.vue";
  import RoofsForm from "@/components/building/form/RoofsForm.vue";
import useEventsBus from "@/utils/eventBus";
  const isRoofTabDisabled = ref(true);
  const route = useRoute();
  let id = ref(route.params.id);

  if (id.value !== '0') {
    isRoofTabDisabled.value = false;
  }

  function enableRoofTab(value: any) {
    id.value = value
    isRoofTabDisabled.value = false;
  }
</script>

<template>
  <Tabs default-value="buildings">
    <TabsList class="justify-center flex h-[3rem]">
      <TabsTrigger value="buildings" class="w-full h-full text-md font-semibold">
        {{ $t('building.building') }}
      </TabsTrigger>
      <TabsTrigger :disabled="isRoofTabDisabled" value="roofs" class="w-full h-full text-md font-semibold">
        {{ $t('building.roofs') }}
      </TabsTrigger>
    </TabsList>
    <div class="w-full bg-gray-400 p-2">
      <h2 v-if="id === '0'" class="text-lg font-semibold leading-none tracking-tight">{{ $t('table.add') }}</h2>
      <h2 v-else class="text-lg font-semibold leading-none tracking-tight">{{ $t('table.edit') }}</h2>
    </div>
    <TabsContent value="buildings">
      <BuildingForm entity="buildings" :id="id" @enable-roofs="enableRoofTab" />
    </TabsContent>
    <TabsContent value="roofs">
      <RoofsForm entity="coverages" :id="id" />
    </TabsContent>
  </Tabs>
</template>

<style scoped>

</style>
