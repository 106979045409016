<script setup lang="ts">
import {i18n} from "@/utils/i18n";
import {ref, watch} from "vue";
import Profile from "@/components/layout/Profile.vue";
import {cn} from "@/utils/utils";
import Nav, {LinkProp} from "@/components/layout/Nav.vue";
import {ResizablePanelGroup, ResizablePanel} from "@/components/ui/resizable";
import {Separator} from "@/components/ui/separator";
import {TooltipProvider} from "@/components/ui/tooltip";

interface MenuProps {
  defaultLayout?: number[]
  defaultCollapsed?: boolean
}

const props = withDefaults(defineProps<MenuProps>(), {
  defaultCollapsed: false,
  defaultLayout: () => [265, 440, 655],
})

const isCollapsed = ref(props.defaultCollapsed)

const version = process.env.VUE_APP_VERSION;

const linksTable: LinkProp[] = [
  {
    title: i18n.global.t('menu.functions.title'),
    icon: 'lucide:users',
    to: { name: 'usersTable'},
    variant: 'ghost',
    root: 'yes',
  },
  {
    title: i18n.global.t('menu.dashboard'),
    icon: 'lucide:layout-dashboard',
    to: { name: 'dashboard'},
    variant: 'default',
    root:'none'
  },
  {
    title: i18n.global.t('menu.buildings'),
    icon: 'lucide:building',
    to: { name: 'buildingsTable'},
    variant: 'ghost',
    root:'none'
  },
  {
    title: i18n.global.t('menu.service_order'),
    icon: 'lucide:clipboard-list',
    to: { name: 'serviceOrdersTable'},
    variant: 'ghost',
    root:'none'
  },
  {
    title: i18n.global.t('menu.tables'),
    icon: 'lucide:users',
    to: { name: 'usersTable'},
    variant: 'ghost',
    root: 'yes',
  },
  {
    title: i18n.global.t('menu.users'),
    icon: 'lucide:users',
    to: { name: 'usersTable'},
    variant: 'ghost',
    root:'none'
  },
  {
    title: i18n.global.t('menu.managers'),
    icon: 'lucide:briefcase-business',
    to: { name: 'managersTable'},
    variant: 'ghost',
    root:'none'
  },
  {
    title: i18n.global.t('menu.masters'),
    icon: 'lucide:key',
    to: { name: 'mastersTable'},
    variant: 'ghost',
    root:'none'
  },
  {
    title: i18n.global.t('menu.coverage_elements'),
    icon: 'lucide:layers-3',
    to: { name: 'coverageElementsTable'},
    variant: 'ghost',
    root:'none'
  },
  {
    title: i18n.global.t('menu.defects'),
    icon: 'lucide:construction',
    to: { name: 'defectsTable'},
    variant: 'ghost',
    root:'none'
  },
  {
    title: i18n.global.t('menu.matrix'),
    icon: 'lucide:grid-2x2',
    to: { name: 'matrixTable'},
    variant: 'ghost',
    root:'none'
  },
  {
    title: i18n.global.t('menu.folders'),
    icon: 'lucide:folder',
    to: { name: 'foldersManage'},
    variant: 'ghost',
    root:'none'
  },
  {
    title: i18n.global.t('menu.other'),
    icon: 'lucide:users',
    to: { name: 'usersTable'},
    variant: 'ghost',
    root: 'yes',
  }
]

function onCollapse() {
  isCollapsed.value = true
}

function onExpand() {
  isCollapsed.value = false
}
</script>

<template>
  <div class="w-1/6 border-r h-[100vh]">
    <TooltipProvider :delay-duration="0">
      <ResizablePanelGroup
          id="resize-panel-group-1"
          direction="horizontal"
          class="h-full max-h-[800px] items-stretch"
      >
        <ResizablePanel
            id="resize-panel-1"
            :default-size="defaultLayout[0]"
            :collapsed-size="4"
            collapsible
            :min-size="15"
            :max-size="20"
            :class="cn(isCollapsed && 'min-w-[950px] transition-all duration-300 ease-in-out')"
            @expand="onExpand"
            @collapse="onCollapse"
        >
          <div :class="cn('flex h-[52px] items-center ml-4', isCollapsed ? 'h-[52px]' : 'px-0')">
            <Profile />
          </div>
          <Separator />
            <span class="ml-4">Software V.: {{version}}</span>
          <Separator />
          <Nav
              :is-collapsed="isCollapsed"
              :links="linksTable"
          />
        </ResizablePanel>
      </ResizablePanelGroup>
    </TooltipProvider>
  </div>
</template>
