<script lang="ts" setup>
import { Icon } from '@iconify/vue';
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/utils/utils";
import { Tooltip, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip";
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export interface LinkProp {
  title: string;
  icon: string;
  to: any;
  variant: 'default' | 'ghost';
  root: string |'none';
}

interface NavProps {
  isCollapsed: boolean;
  links: LinkProp[];
}

const props = defineProps<NavProps>();
const selectedLink = ref<string | null>(null);
const router = useRouter();

const handleLinkClick = async (title: string, to: any) => {
  router.push(to);
  selectedLink.value = title;
};
</script>

<template>
  <div
      :data-collapsed="props.isCollapsed"
      class="group flex flex-col gap-4 data-[collapsed=true]:py-2"
  >


    <nav class="grid gap-1 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
      <template v-for="(link, index) of props.links" :key="index">
        <div v-if="link.root==='yes'">
          <p  class="font-semibold px-5 py-2 bg-gray-400">{{ link.title }}</p>
        </div>
        <div v-else class="px-2">
          <Tooltip v-if="props.isCollapsed" :key="`1-${index}`" :delay-duration="0">
            <TooltipTrigger as-child>
              <a
                  :href="link.to"
                  :class="cn(
                    buttonVariants({ variant: link.variant, size: 'icon' }),
                    'h-9 w-9',
                    link.variant === 'default' && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white',
                    { 'text-red-500': selectedLink === link.title }
                  )"
                  @click.prevent="handleLinkClick(link.title, link.to)"
              >
                <Icon :icon="link.icon" class="size-4" />
                <span class="sr-only">{{ link.title }}</span>
              </a>
            </TooltipTrigger>
            <TooltipContent side="right" class="flex items-center gap-4">
              {{ link.title }}
            </TooltipContent>
          </Tooltip>
          <a
              v-else
              @click.prevent="handleLinkClick(link.title, link.to)"
              :href="link.to"
              :class="cn(
            buttonVariants({ variant: link.variant, size: 'sm' }),
            link.variant === 'default' && 'dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white',
            'justify-start w-full',
            { 'text-red-500': selectedLink === link.title }
          )"
          >
            <Icon :icon="link.icon" class="mr-2 size-4" />
            {{ link.title }}
          </a>
        </div>
      </template>
      <a
          href="./media/pdf/docs.pdf"
          target="_blank"
          :class="cn(
            buttonVariants({ variant: 'ghost', size: 'sm' }),
            'dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white',
            'justify-start w-full',
          )"
      >
        <Icon icon="lucide:circle-help" class="mr-2 size-4" />
        {{ $t('menu.help') }}
      </a>
    </nav>
  </div>
</template>
