<script setup lang="ts">
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

const props = defineProps<{
  isLoading: boolean
}>()
</script>

<template>
  <div class="vld-parent">
    <Loading :active="props.isLoading"
             :is-full-page="true">
    </Loading>
  </div>
</template>
