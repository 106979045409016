<script setup lang="ts">
  import {Button} from "@/components/ui/button";
  import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter, DialogHeader, DialogTitle
  } from "@/components/ui/dialog";
  import {Input} from "@/components/ui/input";
  import TablesService from "@/services/tables/tables.service";
  import useEventsBus from "@/utils/eventBus";
  import {Separator} from "@/components/ui/separator";
  import {toTypedSchema} from "@vee-validate/zod";
  import * as z from "zod";
  import {useForm} from "vee-validate";
  import {watch} from "vue";
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from '@/components/ui/form'

  const props = defineProps<{
    object: any,
    entity: string,
    open: boolean,
    setOpen: any,
  }>()
  const { emit } = useEventsBus();
  let matrix: any = {};
  const formSchema = toTypedSchema(z.object({
    r: z.number(),
    g: z.number(),
    group: z.number(),
    letter: z.string(),
    value1: z.string(),
    value2: z.string(),
    value3: z.string(),
    value4: z.string(),
  }))
  const { handleSubmit, values, setValues } = useForm({
    validationSchema: formSchema,
  })

  watch(values, (newFormData) => {
    matrix = newFormData;
  });

  const onSubmit = handleSubmit(async () => {
    let res;
    if (!props.object.id) {
      res = await TablesService.createRecord(matrix, props.entity);
    } else {
      res = await TablesService.updateRecord(matrix, props.entity);
    }
    if (res != undefined) {
      emit('update_'+props.entity, res.data);
    }
  })

  function parseMatrixValues(matrix: any) {
    const occurrence = JSON.parse(matrix.occurrence);
    matrix.value1 = occurrence.value1;
    matrix.value2 = occurrence.value2;
    matrix.value3 = occurrence.value3;
    matrix.value4 = occurrence.value4;
  }

  watch(() => props.open, async(_, newValue) => {
    if (newValue === false) {
      if (props.object.id) {
        const matrix = props.object;
        if (matrix.occurrence != '') {
          parseMatrixValues(matrix);
        }
        setValues(matrix);
      }
    }
  },{ immediate: true })
</script>

<template>
  <Dialog :open="props.open" @update:open="props.setOpen">
    <DialogContent class="max-w-[60vw]">
      <DialogHeader>
        <DialogTitle v-if="!props.object.id">{{ $t('table.add') }}</DialogTitle>
        <DialogTitle v-else>{{ $t('table.edit') }}</DialogTitle>
        <form @submit="onSubmit" class="mt-3">
          <div class="flex gap-4">
            <FormField v-slot="{ componentField }" name="r">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.r') }}</FormLabel>
                <FormControl>
                  <Input type="number" :placeholder="$t('column.r')" v-bind="componentField" auto-correct="off" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="g">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.g') }}</FormLabel>
                <FormControl>
                  <Input type="number" :placeholder="$t('column.g')" v-bind="componentField" auto-correct="off" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
          <div class="flex gap-4">
            <FormField v-slot="{ componentField }" name="group">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.group') }}</FormLabel>
                <FormControl>
                  <Input type="number" :placeholder="$t('column.group')" v-bind="componentField" auto-correct="off" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="letter">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.letter') }}</FormLabel>
                <FormControl>
                  <Input type="text" :placeholder="$t('column.letter')" v-bind="componentField" auto-correct="off" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
          <Separator class="my-1" />
          <p class="font-weight-bold">{{ $t('column.occurrence_percentage') }}</p>
          <FormField v-slot="{ componentField }" name="value1">
            <FormItem class="w-full">
              <FormLabel>1</FormLabel>
              <FormControl>
                <Input type="text" :placeholder="$t('column.value')" v-bind="componentField" auto-correct="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="value2">
            <FormItem class="w-full">
              <FormLabel>2</FormLabel>
              <FormControl>
                <Input type="text" :placeholder="$t('column.value')" v-bind="componentField" auto-correct="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="value3">
            <FormItem class="w-full">
              <FormLabel>3</FormLabel>
              <FormControl>
                <Input type="text" :placeholder="$t('column.value')" v-bind="componentField" auto-correct="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="value4">
            <FormItem class="w-full">
              <FormLabel>4</FormLabel>
              <FormControl>
                <Input type="text" :placeholder="$t('column.value')" v-bind="componentField" auto-correct="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <Separator class="my-4" />
          <DialogFooter>
            <DialogClose as-child>
              <Button type="button" variant="secondary">
                {{ $t('table.cancel') }}
              </Button>
            </DialogClose>
            <Button type="submit">
              {{ $t('table.confirm') }}
            </Button>
          </DialogFooter>
        </form>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
