<script setup lang="ts">
  import {
    Dialog, DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
  } from '@/components/ui/dialog'
  import {Button} from "@/components/ui/button";
  import TablesService from "@/services/tables/tables.service";
  import {cn, toast} from "@/utils/utils";
  import {i18n} from "@/utils/i18n";
  import {Check, ChevronsUpDown} from "lucide-vue-next";
  import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
  import {FormControl, FormField, FormItem, FormLabel, FormMessage} from "@/components/ui/form";
  import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "@/components/ui/command";
  import {Input} from "@/components/ui/input";
  import {onMounted, ref, watch} from "vue";
  import {toTypedSchema} from "@vee-validate/zod";
  import * as z from "zod";
  import {useForm} from "vee-validate";
  import axios from "axios";
  import {Category} from "@/models/tables/category.interface";
  import Spinner from "@/components/ui_custom/spinner/Spinner.vue";

  const props = defineProps<{
    entity: string,
    entityId: any,
    open: boolean
    setOpen: any
  }>()

  const emit = defineEmits(['edit'])
  const documents: any = ref([]);
  let document: any = {};
  const parentsOpen = ref(false);
  const categories = ref<Category[]>([]);
  const parents = ref([]);
  let isLoading = ref(false);
  const formSchema = toTypedSchema(z.object({
    file: z.any(),
    parentId: z.number(),
  }))

  const { handleSubmit, values, setValues } = useForm({
    validationSchema: formSchema,
  })

  const fetchCategories = async () => {
    const res = await axios.get(process.env.VUE_APP_BASE_API_URL + 'categories/tree', {
      headers: {
        Authorization: process.env.VUE_APP_API_KEY
      }
    });
    categories.value = res.data;
    parents.value = flattenCategories(res.data);
  };

  function flattenCategories(categories: any, level = 0): any {
    let result = [];
    for (const category of categories) {
      let flatCategory = {
        ...category,
        indentedName: `${'. '.repeat(level)}${category.name}`,
        level: level
      };
      result.push(flatCategory);
      if (category.children && category.children.length) {
        result = result.concat(flattenCategories(category.children, level + 1));
      }
    }
    return result;
  }

  watch(values, (newFormData) => {
    document = newFormData;
  });

  async function setFile(event: any) {
    const files = event.target.files ? event.target.files : null;
    for (const file of files) {
      const base64File = await convert(file);
      const blob = dataURLToBlob(base64File);

      documents.value.push({
        src: base64File,
        entityId: props.entityId,
        entity: props.entity,
        originalname: file.name,
        file: blob
      });
    }
  }

  function dataURLToBlob(dataUrl: any) {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  function convert(myFile: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const fileReader = new FileReader();
      if (fileReader && myFile) {
        fileReader.readAsDataURL(myFile);
        fileReader.onload = () => {
          resolve(fileReader.result as any);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        reject('No file provided');
      }
    });
  }

  const onSubmit = handleSubmit(async () => {
    isLoading.value = true;
    const formData = new FormData();
    formData.append('entity', props.entity.toString());
    formData.append('entityId', props.entityId.toString());
    formData.append('categoryId', document.parentId.toString());
    documents.value.forEach(function (document: any) {
      formData.append('files', document.file, document.originalname);
    })
    const res = await TablesService.createBulkAttachments(formData, 'attachments')
    if (res?.data.statusCode === 200) {
      toast.success(i18n.global.t('toast.insert_success'));
      emit('edit')
    }
    documents.value = [];
    isLoading.value = false;
  })

  onMounted(() => {
    fetchCategories();
  });
</script>

<template>
  <Spinner
      :is-loading="isLoading"
  />
  <Dialog :open="props.open" @update:open="props.setOpen">
    <DialogContent class="max-w-[60vw]">
      <DialogHeader>
        <DialogTitle>{{ $t('table.add') }}</DialogTitle>
        <form @submit="onSubmit" class="mt-3">
          <FormField name="file">
            <FormItem class="w-full">
              <FormLabel>{{ $t('column.document') }}</FormLabel>
              <FormControl>
                <Input multiple type="file" id="file" @change="setFile" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField name="parentId">
            <FormItem class="flex flex-col w-full">
              <FormLabel>{{ $t('column.parent') }}</FormLabel>
              <Popover v-model:open="parentsOpen">
                <PopoverTrigger as-child>
                  <FormControl>
                    <Button
                        variant="outline"
                        role="combobox"
                        :class="cn('w-full justify-between', !values.parentId && 'text-muted-foreground')"
                    >
                      {{ values.parentId ? parents.find(
                        (parentId: any) => parentId.id === values.parentId,
                    )?.name : i18n.global.t('table.select') }}
                      <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent class="w-[45vw] p-0">
                  <Command>
                    <CommandInput :placeholder="$t('table.search')" />
                    <CommandEmpty>{{$t('table.no_result')}}</CommandEmpty>
                    <CommandList>
                      <CommandGroup>
                        <CommandItem
                            v-for="parent in parents"
                            :key="parent.id"
                            :value="parent.id"
                            @select="() => {
                              setValues({
                                parentId: parent.id,
                              })
                              parentsOpen = false
                            }"
                        >
                          <Check
                              :class="cn('mr-2 h-4 w-4', parent.id === values.parentId ? 'opacity-100' : 'opacity-0')"
                          />
                          {{ `${'- '.repeat(parent.level) }${parent.name}` }}
                        </CommandItem>
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          </FormField>
          <DialogFooter class="mt-6">
            <DialogClose as-child>
              <Button type="button" variant="secondary">
                {{ $t('table.cancel') }}
              </Button>
            </DialogClose>
            <Button type="submit">
              {{ $t('table.confirm') }}
            </Button>
          </DialogFooter>
        </form>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
