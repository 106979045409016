<script setup lang="ts">
  import {Button} from "@/components/ui/button";
  import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter, DialogHeader, DialogTitle
  } from "@/components/ui/dialog";
  import {Input} from "@/components/ui/input";
  import {computed, ref, watch} from "vue";
  import useEventsBus from "@/utils/eventBus";
  import {toTypedSchema} from "@vee-validate/zod";
  import * as z from "zod";
  import {useForm} from "vee-validate";
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from '@/components/ui/form'
  import {i18n} from "@/utils/i18n";
  import {cn} from "@/utils/utils";
  import {CalendarDate, DateFormatter, getLocalTimeZone, parseDate, today} from "@internationalized/date";
  import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
  import {Calendar} from "@/components/ui/calendar";
  import {Calendar as CalendarIcon} from 'lucide-vue-next'
  import TablesService from "@/services/tables/tables.service";

  const props = defineProps<{
    coverageId: any,
    entity: string,
    open: boolean,
    setOpen: any,
  }>()
  const { emit } = useEventsBus();
  let newRci: any = {};
  const formSchema = toTypedSchema(z.object({
    date: z.string(),
    value: z.number(),
    })
  )
  const { handleSubmit, values, setValues } = useForm({
    validationSchema: formSchema,
  })

  const df = new DateFormatter('it-IT', {
    dateStyle: 'long',
  })
  const date = computed({
    get: () => values.date ? parseDate(values.date) : undefined,
    set: val => val,
  })
  const datePlaceholder = ref();

  watch(values, (newFormData) => {
    if (values.value) {
      setValues({value: +values.value})
    }
    newRci = newFormData;
  });

  const onSubmit = handleSubmit(async () => {
    let res;
    newRci.coverageId = props.coverageId
    res = await TablesService.addRci(newRci);
    if (res != undefined) {
      emit('update_'+props.entity, res.data);
    }
  })
</script>

<template>
  <Dialog :open="props.open" @update:open="props.setOpen">
    <DialogContent class="max-w-[60vw]">
      <DialogHeader>
        <DialogTitle>{{ $t('table.add') }}</DialogTitle>
        <form @submit="onSubmit" class="mt-3">
          <FormField name="date">
            <FormItem class="flex flex-col">
              <FormLabel>{{$t('column.date')}}</FormLabel>
              <Popover>
                <PopoverTrigger as-child>
                  <FormControl>
                    <Button
                        variant="outline" :class="cn(
                  'w-full ps-3 text-start font-normal',
                  !date && 'text-muted-foreground',
                )"
                    >
                      <span>{{ date ? df.format(new Date(date as any)) : i18n.global.t('column.date') }}</span>
                      <CalendarIcon class="ms-auto h-4 w-4 opacity-50" />
                    </Button>
                    <input hidden>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent class="w-auto p-0">
                  <Calendar
                      v-model:placeholder="datePlaceholder"
                      v-model="date"
                      :calendar-label="i18n.global.t('column.date')"
                      initial-focus
                      :min-value="new CalendarDate(2000, 1, 1)"
                      :max-value="today(getLocalTimeZone())"
                      @update:model-value="(v) => {
                if (v) {
                  setValues({
                    date: v.toString(),
                  })
                }
                else {
                  setValues({
                    date: '',
                  })
                }

              }"
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField v-slot="{ componentField }" name="value">
            <FormItem class="w-full">
              <FormLabel>{{ $t('column.rci') }}</FormLabel>
              <FormControl>
                <Input type="text" :placeholder="$t('column.rci')" v-bind="componentField" auto-correct="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <DialogFooter class="mt-6">
            <DialogClose as-child>
              <Button type="button" variant="secondary">
                {{ $t('table.cancel') }}
              </Button>
            </DialogClose>
            <Button type="submit">
              {{ $t('table.confirm') }}
            </Button>
          </DialogFooter>
        </form>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
