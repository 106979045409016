<script setup lang="ts">
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import {Button, buttonVariants} from "@/components/ui/button";
import {cn, loggedUser} from "@/utils/utils";
import {Icon} from "@iconify/vue";
import router from "@/router";

const user: any = loggedUser.getLoggedUserFromStorage();

const nameFirstLetter = user.name.substring(0, 1);
const surnameFirstLetter = user.surname.substring(0, 1);

async function logout() {
  localStorage.removeItem('user');
  await router.push({name: 'signin'})
}
</script>

<template>
  <div class="flex w-full gap-4">
    <Button variant="ghost" class="relative h-8 w-full rounded-full p-0 justify-start cursor-default" @click.prevent="$router.push({ name: 'dashboard' })">
      <Avatar class="h-8 w-8 mr-5">
        <AvatarFallback>{{ nameFirstLetter }}{{ surnameFirstLetter }}</AvatarFallback>
      </Avatar>
      <p class="font-semibold">
        {{ user.name }} {{ user.surname }}
      </p>
    </Button>
    <div
        @click.prevent="logout"
        class="w-2/12 justify-end hover:cursor-pointer"
        :class="cn(
            buttonVariants({ variant: 'ghost', size: 'sm' }),
            'dark:text-white dark:hover:bg-muted dark:hover:text-white',
            'justify-start w-full',
          )"
    >
      <Icon icon="lucide:log-out" class="mr-2 size-4" />
    </div>
  </div>
</template>

<style scoped>

</style>
