<script setup lang="ts">
  import {Button} from "@/components/ui/button";
  import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter, DialogHeader, DialogTitle
  } from "@/components/ui/dialog";
  import {Input} from "@/components/ui/input";
  import TablesService from "@/services/tables/tables.service";
  import useEventsBus from "@/utils/eventBus";
  import {toTypedSchema} from "@vee-validate/zod";
  import * as z from "zod";
  import {useForm} from "vee-validate";
  import {onMounted, ref, watch} from "vue";
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from '@/components/ui/form'
  import {cn} from "@/utils/utils";
  import {i18n} from "@/utils/i18n";
  import {Check, ChevronsUpDown} from "lucide-vue-next";
  import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
  import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "@/components/ui/command";

  const props = defineProps<{
    object: any,
    parents?: any,
    entity: string,
    open: boolean,
    setOpen: any,
  }>()
  const { emit } = useEventsBus();
  let folder: any = {};
  const parentsOpen = ref(false);
  const formSchema = toTypedSchema(z.object({
    name: z.string(),
    parentId: z.any(),
  }))

  const { handleSubmit, values, setValues } = useForm({
    validationSchema: formSchema,
  })

  watch(values, (newFormData) => {
    folder = newFormData;
  });

  const onSubmit = handleSubmit(async () => {
    let res;
    if (!props.object.id) {
      res = await TablesService.createRecord(folder, props.entity);
    } else {
      res = await TablesService.updateRecord(folder, props.entity);
    }
    if (res != undefined) {
      emit('update_'+props.entity, res.data);
    }
  })

  watch(() => props.open, async(_, newValue) => {
    if (newValue === false) {
      if (props.object.id) {
        await initializeForm()
      }
    }
  },{ immediate: true })

  async function initializeForm() {
    const res = await TablesService.getElementById(props.entity, props.object.id);
    if (res?.data) {
      setValues(res.data);
    }
  }
</script>

<template>
  <Dialog :open="props.open" @update:open="props.setOpen">
    <DialogContent class="max-w-[60vw]">
      <DialogHeader>
        <DialogTitle v-if="!props.object.id">{{ $t('table.add') }}</DialogTitle>
        <DialogTitle v-else>{{ $t('table.edit') }}</DialogTitle>
        <form @submit="onSubmit" class="mt-3">
          <FormField v-slot="{ componentField }" name="name">
            <FormItem class="w-full">
              <FormLabel>{{ $t('column.name') }}</FormLabel>
              <FormControl>
                <Input type="text" :placeholder="$t('column.name')" v-bind="componentField" auto-correct="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField name="parentId">
            <FormItem class="flex flex-col w-full">
              <FormLabel>{{ $t('column.parent') }}</FormLabel>
              <Popover v-model:open="parentsOpen">
                <PopoverTrigger as-child>
                  <FormControl>
                    <Button
                        variant="outline"
                        role="combobox"
                        :class="cn('w-full justify-between', !values.parentId && 'text-muted-foreground')"
                    >
                      {{ values.parentId ? props.parents.find(
                        (parentId: any) => parentId.id === values.parentId,
                    )?.name : i18n.global.t('table.select') }}
                      <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent class="w-[45vw] p-0">
                  <Command>
                    <CommandInput :placeholder="$t('table.search')" />
                    <CommandEmpty>{{$t('table.no_result')}}</CommandEmpty>
                    <CommandList>
                      <CommandGroup>
                        <CommandItem
                            v-for="parent in props.parents"
                            :key="parent.id"
                            :value="parent.id"
                            @select="() => {
                              setValues({
                                parentId: parent.id,
                              })
                              parentsOpen = false
                            }"
                        >
                          <Check
                              :class="cn('mr-2 h-4 w-4', parent.id === values.parentId ? 'opacity-100' : 'opacity-0')"
                          />
                          {{ `${'- '.repeat(parent.level) }${parent.name}` }}
                        </CommandItem>
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          </FormField>
          <DialogFooter class="mt-6">
            <DialogClose as-child>
              <Button type="button" variant="secondary">
                {{ $t('table.cancel') }}
              </Button>
            </DialogClose>
            <Button type="submit">
              {{ $t('table.confirm') }}
            </Button>
          </DialogFooter>
        </form>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
