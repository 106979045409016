import { defineStore } from 'pinia';

export const useNavStore = defineStore("nav", {
    state: () => ({
        nav: false,
    }),
    actions: {
        async setNav(display: boolean){
            this.nav = display;
            return this.nav;
        },

        async getNav(){
            return this.nav;
        },
    }
});
