<script setup lang="ts">
  import DataTable from "@/components/ui_custom/data-table/DataTable.vue";
  import {onMounted, ref, watch} from "vue";
  import {columns} from "./columns"
  import TablesService from "@/services/tables/tables.service";
  import useEventsBus from "@/utils/eventBus";
  import RciEditForm from "@/components/tables/rci/RciEditForm.vue";
  import {toast} from "@/utils/utils";
  import {i18n} from "@/utils/i18n";
  import {useRoute} from "vue-router";

  const props = defineProps<{
    coverageId: number,
  }>()

  const data = ref<any[]>([]);
  const route = useRoute();
  const { bus } = useEventsBus();
  const entity = 'coverages';
  const isAddDialogOpen = ref(false);

  async function getData() {
    const res = await TablesService.getElementById(entity, props.coverageId);
    if (res?.data) {
      return data.value = JSON.parse(res.data.rci);
    }
  }

  const setIsAddDialogOpen = (value: boolean) => {
    isAddDialogOpen.value = value;
  };

  async function deleteRoofIndex(value: any) {
    const body = value;
    body.coverageId = Number(route.params.id);
    const res = await TablesService.deleteRci(body);
    if (res?.data) {
      data.value = data.value.filter(row => row != value);
      return toast.success(i18n.global.t('toast.delete_success'));
    }
  }

  onMounted(async () => {
    data.value = await getData();
  })

  watch(() => bus.value.get('new_'+entity), async () => {
    setIsAddDialogOpen(true);
  })

  watch(() => bus.value.get('delete_rci'), async (value) => {
    await deleteRoofIndex(value);
  })

  watch(() => bus.value.get('update_'+entity), async () => {
    await getData();
    setIsAddDialogOpen(false);
    toast.success(i18n.global.t('toast.edit_success'));
  })
</script>

<template>
  <div class="container py-10 mx-auto">
    <DataTable :columns="columns" :data="data" :entity="entity" />
  </div>
  <RciEditForm :entity="entity" :coverage-id="props.coverageId" :open="isAddDialogOpen" :set-open="setIsAddDialogOpen" />
</template>

<style scoped>

</style>
