import { createI18n } from "vue-i18n";
import it from "@/translations/it.json";

export const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'it',
    fallbackLocale: 'en',
    messages: { it: it }
})

export const currentLocale = i18n.global.locale.value;
