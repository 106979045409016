<script lang="ts">
import {HTMLAttributes, InjectionKey, provide} from 'vue'
import {useId} from "radix-vue";
import{cn} from "@/utils/utils"

export const FORM_ITEM_INJECTION_KEY
  = Symbol() as InjectionKey<string>

export default {
  methods: {cn},
  props: {
    propClass: {
      type: String,
      required: false
    }
  },
  setup() {
    const id = useId()
    provide(FORM_ITEM_INJECTION_KEY, id)
  }
}
</script>

<template>
  <div :class="cn('space-y-2', propClass)">
    <slot />
  </div>
</template>
