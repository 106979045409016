<script setup lang="ts" generic="TData, TValue">
  import { Plus } from 'lucide-vue-next'
  import {
    ColumnDef, FlexRender,
    getCoreRowModel,
    useVueTable,
  } from '@tanstack/vue-table'
  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from '@/components/ui/table'
  import { Button } from "@/components/ui/button";
  import useEventsBus from "@/utils/eventBus";

  const props = defineProps<{
    // eslint-disable-next-line no-undef
    columns: ColumnDef<TData, TValue>[]
    // eslint-disable-next-line no-undef
    data: TData[]
    entity: string,
    count? : number
  }>()
  const { emit } = useEventsBus();

  const table = useVueTable({
    get data() { return props.data },
    get columns() { return props.columns },
    getCoreRowModel: getCoreRowModel(),
  })
</script>

<template>
  <div>
    <div class="flex items-center py-2">
<!--
      <p v-if="props.count" class="font-semibold">
        {{ $t('dashboard.total')}}: {{ props.count }}
      </p>
-->
      <Button class="ml-auto" @click="emit('new_'+props.entity, {})">
        <Plus class="mr-2 h-5 w-5" />
        {{ $t('table.add') }}
      </Button>
    </div>
    <div class="border rounded-md">
      <Table>
        <TableHeader class="bg-gray-300 font-black">
          <TableRow v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
            <TableHead v-for="header in headerGroup.headers" :key="header.id">
              <FlexRender v-if="!header.isPlaceholder" :render="header.column.columnDef.header"
                          :props="header.getContext()" />
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <template v-if="table.getRowModel().rows?.length">
            <TableRow v-for="row in table.getRowModel().rows" :key="row.id"
                      :data-state="row.getIsSelected() ? 'selected' : undefined">
              <TableCell v-for="cell in row.getVisibleCells()" :key="cell.id">
                <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
              </TableCell>
            </TableRow>
          </template>
          <template v-else>
            <TableRow>
              <TableCell :colSpan="columns.length" class="h-24 text-center">
                {{ $t('table.no_result') }}
              </TableCell>
            </TableRow>
          </template>
        </TableBody>
      </Table>
    </div>
  </div>
</template>

<style scoped>

</style>
