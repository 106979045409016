import { h } from 'vue'
import { ColumnDef } from "@tanstack/vue-table";
import DropdownAction from '@/components/tables/rci/RciDataTableDropDown.vue'
import {i18n} from "@/utils/i18n";
import {Roof} from "@/models/tables/roof.interface";

export const columns: ColumnDef<Roof>[] = [
    {
        accessorKey: 'date',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.date')),
        cell: ({ row }) => {
            const date = new Date(row.getValue('date')).toLocaleDateString();
            return h('div', { class: 'text-right font-medium' }, date)
        },
    },
    {
        accessorKey: 'value',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.rci')),
        cell: ({ row }) => {
            const value = String(row.getValue('value'));
            return h('div', { class: 'text-right font-medium' }, value)
        },
    },
    {
        id: 'actions',
        cell: ({ row }) => {
            const object = row.original
            return h('div', { class: 'relative' }, h(DropdownAction, { object }))
        },
    },
]
