<script setup lang="ts">
  import {onMounted, ref} from "vue";
  import { Camera, SwitchCamera, SaveAll, X, Folder } from 'lucide-vue-next'
  import {useRoute} from "vue-router";
  import {Button} from "@/components/ui/button";
  import router from "@/router";
  import {Card, CardContent} from "@/components/ui/card";
  import {Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious} from "@/components/ui/carousel";
  import TablesService from "@/services/tables/tables.service";
  import {toast} from "@/utils/utils";
  import {i18n} from "@/utils/i18n";
  import {Input} from "@/components/ui/input";
  import Spinner from "@/components/ui_custom/spinner/Spinner.vue";

  const photos: any = ref([]);
  let mediaStream: any = ref(null);
  let videoDevices: any = ref([])
  let facingMode: any = ref("environment")
  const counter = ref(0)
  let switchingCamera = ref(false)
  const route = useRoute();
  const id = route.params.id;
  const entity = route.params.entity;
  let serviceOrderId: any = '';
  let isLoading = ref(false);
  if (route.params.serviceOrderId) {
    serviceOrderId = route.params.serviceOrderId;
  }

  async function startRecording(FacingMode: any) {
    facingMode = FacingMode;
    let video: any = document.querySelector('video');
    mediaStream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: facingMode },
    });
    video.srcObject = mediaStream;
    return await video.play();
  }

  async function takePhoto() {
    let video: any = document.querySelector('video');
    let canvas: any = document.querySelector('canvas');

    if (!video || !canvas) return;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    let ctx = canvas.getContext("2d");

    if (facingMode.value === "user") {
      ctx.scale(-1, 1);
      ctx.drawImage(video, video.width * -1, 0, video.width, video.height);
    } else {
      ctx.drawImage(video, 0, 0);
    }
    const dataUrl = canvas.toDataURL("image/jpg");
    const blob = dataURLToBlob(dataUrl)

    photos.value.push({
      id: counter.value++,
      src: dataUrl,
      originalname: 'blob',
      entityId: id,
      entity: entity,
      file: blob
    });
  }

  async function submitPhotos() {
    isLoading.value = true;
    const formData = new FormData();
    formData.append('entity', entity.toString());
    formData.append('entityId', id.toString());
    formData.append('serviceOrderId', serviceOrderId.toString());
    photos.value.forEach(function (photo: any) {
      formData.append('files', photo.file, photo.originalname);
    })
    const res = await TablesService.createBulkAttachments(formData, 'attachments')
    if (res?.data.statusCode === 200) {
      toast.success(i18n.global.t('toast.insert_success'));
      router.back();
    }
    isLoading.value = false;
  }

  function dataURLToBlob(dataUrl: any) {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  async function switchCamera() {
    switchingCamera.value = true;
    if (mediaStream.value) {
      const tracks = mediaStream.value.getVideoTracks();
      tracks.forEach((track: any) => {
        track.stop();
      });
    }
    await startRecording(
        facingMode.value === "environment" ? "environment" : "user"
    );
    switchingCamera.value = false;
  }

  function removePhoto(id: any) {
    photos.value = photos.value.filter((obj: any) => obj.id !== id)
  }

  function upload(): Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      const filePicker = document.querySelector('input');

      if (!filePicker || !filePicker.files
          || filePicker.files.length <= 0) {
        reject('No file selected.');
        return;
      }

      const fileArray: any = filePicker.files;
      for (const file of fileArray) {
        const base64File = await convert(file);
        const blob = dataURLToBlob(base64File);
        photos.value.push({
          id: counter.value++,
          src: base64File,
          entityId: id,
          entity: entity,
          originalname: file.name,
          file: blob
        });
      }
    });
  }

  function convert(myFile: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const fileReader = new FileReader();
      if (fileReader && myFile) {
        fileReader.readAsDataURL(myFile);
        fileReader.onload = () => {
          resolve(fileReader.result as any);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        reject('No file provided');
      }
    });
  }

  onMounted(async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    videoDevices.value = devices.filter((d) => d.kind === "videoinput");
    await startRecording(
        videoDevices.value.length === 1 ? "user" : "environment"
    )
  })
</script>

<template>
  <Spinner
      :is-loading="isLoading"
  />
  <div class="bg-black grid w-[100vw] h-[100vh] justify-items-center overflow-hidden video-wrapper">
    <video class="h-[100%] video z-0" :class="facingMode === 'user' ? 'front' : ''" ref="video"/>
    <canvas style="display: none" ref="canva" />
    <Button class="switch-button z-10 rounded-[100%] bg-gray-200 w-[6vh] h-[6vh] items-center justify-center text-black hover:bg-gray-600" @click="router.back()">
      <X class="h-6 w-6" />
    </Button>
    <Button
        class="switch-button z-10 rounded-[100%] bg-gray-200 w-[6vh] h-[6vh] items-center grid justify-center text-black hover:bg-gray-600 mt-[10vh]"
        @click="switchCamera"
    >
      <SwitchCamera class="h-6 w-6" />
    </Button>
    <label for="gallery" class=" cursor-pointer switch-button z-10 rounded-[100%] bg-gray-200 w-[6vh] h-[6vh] items-center grid justify-center text-black hover:bg-gray-600 mt-[20vh]">
      <Folder class="h-6 w-6" />
    </label>
    <input
        id="gallery"
        type="file"
        multiple
        class="hidden"
        @change="upload"
    />
    <Button
        class="switch-button z-10 rounded-[100%] bg-gray-200 w-[6vh] h-[6vh] items-center justify-center text-black hover:bg-gray-600 mt-[30vh]"
        @click="submitPhotos"
        :disabled="photos.length === 0"
    >
      <SaveAll class="h-6 w-6" />
    </Button>
    <div class="photo-button-container z-10 w-[100vw] h-[10vh] flex justify-center">
      <Button class="bg-gray-200 w-[8vh] h-[8vh] rounded-[100%] items-center grid justify-center text-black hover:bg-gray-600" @click="takePhoto">
        <Camera class="h-10 w-10" />
      </Button>
    </div>
    <Carousel v-if="photos.length > 0" class="relative w-[43%]" :opts="{ align: 'start' }">
      <CarouselContent class="-ml-1">
        <CarouselItem v-for="photo in photos" :key="photo.id" class="pl-1 md:basis-1/2 lg:basis-1/3">
          <div class="p-1">
            <Card class="bg-black border-black">
              <CardContent class="flex aspect-square items-center justify-center p-0">
                <Button class="absolute p-2 rounded-full bg-gray-200 text-red-700" @click.prevent="removePhoto(photo.id)">
                  <X class="w-5 h-5"></X>
                </Button>
                <img :src="photo.src" :alt="photo.id" class="w-full" />
              </CardContent>
            </Card>
          </div>
        </CarouselItem>
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  </div>
</template>

<style scoped>
  .video.front {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .video-wrapper {
    grid-template-columns: [left] 90vw [bs] 5vw [es] 5vw [right];
    grid-template-rows: [top] 5vh [bs] 5vh [es] 50vh [middle] 10vh [bottom] 20vh [end];
  }

  .video {
    grid-column: left/right;
    grid-row: top / bottom;
    user-select: none;
    max-width: unset;
  }

  .switch-button {
    grid-column: bs / es;
    grid-row: bs / es;
  }

  .photo-button-container {
    grid-column: left / right;
    grid-row: middle / bottom;
  }
</style>
