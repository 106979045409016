import { defineStore } from 'pinia';

export const useRoofStore = defineStore("roof", {
    state: () => ({
        roof: {},
        roof_list: <any>[],
        element_list: <any>[]
    }),
    actions: {
        async setRoofList(roofs: any){
            this.roof_list = roofs;
            return this.roof_list;
        },

        async setRoof(roof: object){
            this.roof = roof;
            return this.roof;
        },

        async getRoofList(){
            return this.roof_list;
        },

        async getRoof(){
            return this.roof;
        },

        addRoofToList: async function(roof: any):Promise<any> {
            this.roof_list = this.roof_list.map((obj: any) =>
                obj === roof ? roof : obj,
            );
            const objectFound = this.roof_list.some((obj: any) => obj === roof);
            if (!objectFound) {
                this.roof_list.push(roof);
            }
            return this.roof_list;
        },

        removeRoofFromList(roof: any) {
            this.roof_list = this.roof_list.filter((obj: any) => obj !== roof);
            return this.roof_list;
        },

        addElementToList: async function(element: any):Promise<any> {
            this.element_list = this.element_list.map((obj: any) =>
                obj === element ? element : obj,
            );
            const objectFound = this.element_list.some((obj: any) => obj === element);
            if (!objectFound) {
                this.element_list.push(element);
            }
            return this.element_list;
        },

        removeElementFromList(element: any) {
            this.element_list = this.element_list.filter((obj: any) => obj !== element);
            return this.element_list;
        },

        async clearRoofList(){
            this.roof_list = [];
            return this.roof_list;
        },

        clearRoof() {
            this.roof = {};
            this.element_list = [];
            return this.roof
        }
    }
});
