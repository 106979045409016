import axios from "axios";
import {toast} from "@/utils/utils";

export default class TablesService {
    static async getData(entity: string) {
        try {
            return await axios.get(process.env.VUE_APP_BASE_API_URL + entity, {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }

    static async getAttachmentById(id: string, type: string) {
        try {
            return await axios.get(process.env.VUE_APP_BASE_API_URL + 'attachments/' + id + '/' + type, {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }

    static async getAttachments(entity: string, entityId: string, type: string) {
        try {
            return await axios.get(process.env.VUE_APP_BASE_API_URL + 'attachments/' + entity + '/' + entityId + '/' + type, {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }

    static async getTreeView(entity: string, entityId: string) {
        try {
            return await axios.get(process.env.VUE_APP_BASE_API_URL + 'categories/tree/' + entity + '/' + entityId, {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }

    static async getDefectsAttachments(serviceOrderId: string) {
        try {
            return await axios.get(process.env.VUE_APP_BASE_API_URL + 'attachments/' + serviceOrderId, {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }

    static async deleteRecord(id: number, entity: string) {
        try {
            return await axios.delete(process.env.VUE_APP_BASE_API_URL + entity + '/' + id, {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e?.response.data.message);
        }
    }

    static async bulkDeleteAttachments(aId: number[]) {
        try {
            return await axios.delete(process.env.VUE_APP_BASE_API_URL + 'attachments/bulk/delete', {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                },
                data: aId
            });
        } catch (e: any) {
            toast.error(e?.response.data.message);
        }
    }

    static async updateRecord(body: any, entity: string) {
        try {
            return await axios.patch(process.env.VUE_APP_BASE_API_URL + entity + '/' + body.id, body,{
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }

    static async createRecord(body: any, entity: string) {
        try {
            return await axios.post(process.env.VUE_APP_BASE_API_URL + entity, body,{
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }

    static async createBulkRecord(body: any, entity: string) {
        try {
            return await axios.post(process.env.VUE_APP_BASE_API_URL + entity + '/bulk', { bulk: body },{
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }

    static async createBulkAttachments(body: any, entity: string) {
        try {
            return await axios.post(process.env.VUE_APP_BASE_API_URL + entity + '/bulk/upload', body,{
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }

    static async getElementById(entity: string, id: any) {
        try {
            return await axios.get(process.env.VUE_APP_BASE_API_URL + entity + '/' + id, {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }

    static async addRci(body: any) {
        try {
            return await axios.post(process.env.VUE_APP_BASE_API_URL + 'coverages/add/roof-index', body,{
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }

    static async deleteRci(body: any) {
        try {
            return await axios.delete(process.env.VUE_APP_BASE_API_URL + 'coverages/delete/roof-index', {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                },
                data: {
                    body
                }
            });
        } catch (e: any) {
            toast.error(e.response?.data.message);
        }
    }

    static async getCoverageServiceOrderById(entity: string, id: any, serviceOrderId: any) {
        try {
            return await axios.get(process.env.VUE_APP_BASE_API_URL + entity + '/' + id + '/' + serviceOrderId, {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }
}
