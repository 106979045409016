<script setup lang="ts">
  import {useRoute} from "vue-router";
  import {onMounted, ref} from "vue";
  import TablesService from "@/services/tables/tables.service";
  import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/card";
  import RoofCarousel from "@/components/building/view/components/RoofCarousel.vue";
  import {LineChart} from "@/components/ui/chart-line";
  import {File, Calculator, FileCode2, FilePlus, StepBack} from "lucide-vue-next";
  import {Button} from "@/components/ui/button";
  import axios from "axios";
  import CalculationDataDialog from "@/components/roof/CalculationDataDialog.vue";
  import CustomChartTooltip from "@/components/roof/CustomChartTooltip.vue";
  import UploadDocumentDialog from "@/components/building/UploadDocumentDialog.vue";

  const route = useRoute();
  let roof: any = ref({})

  const data = ref([]);
  const isCalculationDialogOpen = ref(false);
  const setIsCalculationDialogOpen = (value: any) => {
    isCalculationDialogOpen.value = value;
  };
  const isDocumentDialogOpen = ref(false);
  const setIsDocumentDialogOpen = (value: boolean) => {
    isDocumentDialogOpen.value = value;
  };

  const getExcel = async (id: number) => {

    try {
      const res = await axios.get(process.env.VUE_APP_BASE_API_URL + 'service-order/excel/'+id, {
        headers: {
          Authorization: process.env.VUE_APP_API_KEY
        },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Errore nel download del file', error);
    }
  };

  onMounted(async () => {
    const res = await TablesService.getElementById('coverages', route.params.id)
    if (res?.data) {
      roof.value = res.data
      if (roof.value.coverageData && roof.value.coverageData.length > 0) {
        roof.value.groupedCoverageData = roof.value.coverageData.reduce((result: any, currentValue: any) => {
          (result[currentValue['serviceOrder']['id']] = result[currentValue['serviceOrder']['id']] || []).push(currentValue);
          return result;
        }, {})
      }
      data.value = JSON.parse(res.data.rci)
    }
  })
</script>

<template>
  <div class="w-5/6 h-[100vh] p-[2%]">
    <Button @click.prevent="$router.back()" type="button" class="text-center mb-4">
      <StepBack class="mr-2 h-5 w-5" />
      {{ $t('building.back') }}
    </Button>
    <div class="md:flex">
      <div class="w-full">
        <Card class="h-[53vh]">
          <CardHeader class="p-5">
            <CardTitle class="text-lg">
              {{ $t('building.rci') }}
            </CardTitle>
          </CardHeader>
          <CardContent class="px-4 w-full h-[85%]">
            <LineChart
                :data="data"
                index="date"
                :categories="['value']"
                :custom-tooltip="CustomChartTooltip"
            />
          </CardContent>
        </Card>
      </div>
      <RoofCarousel :entity-id="route.params.id" entity="coverages" />
    </div>
    <div class="md:flex gap-4">
      <div class="w-full mt-6">
        <Card class="h-full">
          <CardHeader class="p-5 bg-gray-400">
            <CardTitle class="text-lg">
              {{ $t('building.info') }}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.name') }}:</p>
                <p> {{ roof.name }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.code') }}:</p>
                <p> {{ roof.code }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.description') }}:</p>
                <p> {{ roof.description }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.installation_year') }}:</p>
                <p> {{ roof.installationYear }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.dimensions') }}:</p>
                <p> {{ roof.dimensions }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.slope') }}:</p>
                <p> {{ roof.slope }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.construction_cost') }} (€):</p>
                <p> {{ roof.constructionCost }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.estimated_life') }} ({{ $t('column.years') }}):</p>
                <p> {{ roof.estimatedLife }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.intervention_cost') }} (€):</p>
                <p> {{ roof.interventionCost }}</p>
              </div>
              <Button @click.prevent="$router.push({ name: 'documentsView', params: { entity:'coverages', id: roof.id } })" type="button" class="w-full text-center mt-6">
                <File class="mr-2 h-5 w-5" />
                {{ $t('building.document_gallery') }}
              </Button>
              <Button @click.prevent="setIsDocumentDialogOpen(true)" type="button" class="w-full text-center mt-6">
                <FilePlus class="mr-2 h-5 w-5" />
                {{ $t('building.add_document') }}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      <div class="w-full mt-6">
        <Card class="h-full">
          <CardHeader class="p-5 bg-gray-400">
            <CardTitle class="text-lg">
              {{ $t('building.calculation_data') }}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div v-if="roof.groupedCoverageData">
              <div v-for="(data, key) in roof.groupedCoverageData" :key="key">
                <div class="flex gap-4">
                  <Button @click.prevent="setIsCalculationDialogOpen(data[0].serviceOrder.id)" type="button" class="w-[65%] text-center mt-6">
                    <Calculator class="mr-2 h-5 w-5" />
                    Dati {{ $t('menu.service_order') }} del {{ new Date(data[0].serviceOrder.date).toLocaleDateString() }}
                  </Button>
                  <CalculationDataDialog :set-open="setIsCalculationDialogOpen" :open="isCalculationDialogOpen === data[0].serviceOrder.id" :object="data" />
                  <Button @click.prevent="getExcel(data[0].serviceOrder.id)" type="button" class="w-[35%] text-center mt-6">
                    <FileCode2 class="mr-2 h-4 w-4"></FileCode2>
                    {{ $t('table.excel') }}
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  </div>
  <UploadDocumentDialog entity="coverages" :entity-id="route.params.id" :open="isDocumentDialogOpen" :set-open="setIsDocumentDialogOpen" @edit="setIsDocumentDialogOpen(false)" />
</template>

<style scoped>

</style>
