<script setup lang="ts">
  import { ref } from 'vue'
  import {cn, toast} from '@/utils/utils'
  import { Input } from "@/components/ui/input";
  import { Button } from "@/components/ui/button";
  import SigninService from "@/services/signin/signin.service";
  import {toTypedSchema} from "@vee-validate/zod";
  import * as z from "zod";
  import {useForm} from "vee-validate";
  import {FormControl, FormField, FormItem, FormMessage} from "@/components/ui/form";

  const isLoading = ref(false)
  const formSchema = toTypedSchema(z.object({
    email: z.string().email(),
  }))
  const { handleSubmit } = useForm({
    validationSchema: formSchema,
  })

  const onSubmit = handleSubmit(async (values) => {
    const res = await SigninService.forgotPassword(values.email);
    if (res?.data.statusCode === 200) {
      isLoading.value = false;
      toast.success(res.data.message, { timeout: 20000 });
    } else {
      toast.error(res?.data.message);
    }
    isLoading.value = false;

    setTimeout(() => {
      isLoading.value = false
    }, 3000)
  })
</script>

<template>
  <div :class="cn('grid gap-6')">
    <form @submit.prevent="onSubmit">
      <div class="grid gap-2">
        <div class="grid gap-1">
          <FormField v-slot="{ componentField }" name="email">
            <FormItem class="w-full">
              <FormControl>
                <Input type="email" :placeholder="$t('column.email')" v-bind="componentField" auto-correct="off" auto-capitalize="none" auto-complete="email" :disabled="isLoading" />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>
        <Button :disabled="isLoading">
          {{ $t('login.forgot_password') }}
        </Button>
      </div>
    </form>
  </div>
</template>

<style scoped>

</style>
