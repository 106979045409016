<script setup lang="ts">
import {StepBack} from "lucide-vue-next";
import {Button} from "@/components/ui/button";
import RciTable from "@/components/tables/rci/RciTable.vue";
import {useRoute} from "vue-router";

const route = useRoute()
</script>

<template>
    <div class="p-[2%] w-5/6">
      <Button @click.prevent="$router.back()" type="button" class="text-center">
        <StepBack class="mr-2 h-5 w-5" />
        {{ $t('building.back') }}
      </Button>
      <RciTable :coverageId="Number(route.params.id)" />
    </div>
</template>

<style scoped>

</style>
