<script setup lang="ts">
  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from '@/components/ui/table'
  import {MoreHorizontal, Trash2} from "lucide-vue-next";
  import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger
  } from "@/components/ui/dropdown-menu";
  import {Button} from "@/components/ui/button";
  import {CoverageElement} from "@/models/tables/coverageElement.interface";

  const props = defineProps<{
    elements: CoverageElement[],
    entity: string
  }>()
</script>

<template>
  <h3 class="font-semibold mt-6">{{$t('building.elements_list')}}</h3>
  <Table>
    <TableHeader class="bg-gray-300 font-black">
      <TableRow>
        <TableHead>{{$t('column.name')}}</TableHead>
        <TableHead>{{$t('column.code')}}</TableHead>
        <TableHead class="text-right">{{$t('table.actions')}}</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      <TableRow v-for="element in props.elements" :key="element.code">
        <TableCell>{{element.code}}</TableCell>
        <TableCell>{{element.description}}</TableCell>
        <TableCell class="grid justify-end">
          <DropdownMenu>
            <DropdownMenuTrigger as-child>
              <Button variant="ghost" class="w-8 h-8 p-0">
                <MoreHorizontal class="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>{{ $t('table.actions') }}</DropdownMenuLabel>
              <DropdownMenuItem @click.prevent="$emit('delete', element)" class="cursor-pointer">
                <Trash2 class="mr-2 h-4 w-4" />
                {{ $t('table.delete') }}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
</template>

<style scoped>

</style>
