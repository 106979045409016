import { h } from 'vue'
import { ColumnDef } from "@tanstack/vue-table";
import DropdownAction from '@/components/tables/users/UsersDataTableDropDown.vue'
import {currentLocale, i18n} from "@/utils/i18n";
import {User} from "@/models/tables/user.interface";
import moment from "moment";

export const columns: ColumnDef<User>[] = [
    {
        accessorKey: 'id',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.id')),
        cell: ({ row }) => {
            const id = Number(row.getValue('id'));
            return h('div', { class: 'text-right font-medium' }, id)
        },
    },
    {
        accessorKey: 'name',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.name')),
        cell: ({ row }) => {
            const name = String(row.original.name);
            const surname = String(row.original.surname);
            const formattedName = name+' '+surname;
            return h('div', { class: 'text-right font-medium' }, formattedName)
        },
    },
    {
        accessorKey: 'email',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.email')),
        cell: ({ row }) => {
            const email = String(row.getValue('email'));
            return h('div', { class: 'text-right font-medium' }, email)
        },
    },
    {
        accessorKey: 'lastLogin',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.last_login')),
        cell: ({ row }) => {
            const lastLogin = String(row.getValue('lastLogin'));
            const formattedLastLogin = moment(lastLogin).locale(currentLocale).format('ddd D MMMM YYYY HH:mm');
            return h('div', { class: 'text-right font-medium' }, formattedLastLogin)
        },
    },
    {
        accessorKey: 'enabled',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('table.enabled')),
        cell: ({ row }) => {
            const enabled = Boolean(row.getValue('enabled'));
            let i18nEnabled = i18n.global.t('table.disabled');
            if (enabled) {
                i18nEnabled = i18n.global.t('table.enabled');
            }
            return h('div', { class: 'text-right font-medium' }, i18nEnabled)
        },
    },
    {
        id: 'actions',
        cell: ({ row }) => {
            const object = row.original
            return h('div', { class: 'relative' }, h(DropdownAction, { object, entity: 'users' }))
        },
    },
]
