<template>
  <div v-if="isUpdateAvailable"  class="update-notification w-full h-auto" style="z-index: 1000">
    <p class="text-black">Un nuovo aggiornamento è disponibile.</p>
    <button @click="reloadPageWithBypassCache" class="text-black">Aggiorna</button>
  </div>
  <div class="flex">
    <Menu v-if="navStore.nav" />
    <router-view />
  </div>
</template>
<script setup lang="ts">
import {getCurrentInstance, onBeforeUnmount, onMounted, ref} from "vue";
  import {useColorMode} from "@vueuse/core";
  import Menu from '@/components/layout/Menu.vue';

  let mode = useColorMode();
  const isUpdateAvailable = ref(false);
  const instance = getCurrentInstance();
  const navStore = instance?.appContext.config.globalProperties.$navStore;

  function handleBeforeUnload(event: Event) {
    event.preventDefault();
  }

  onMounted(() => {
    mode.value = 'dark';
    // window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('swUpdated', handleServiceWorkerUpdate);
  });

  onBeforeUnmount(() => {
    // window.removeEventListener('beforeunload', handleBeforeUnload);
    document.removeEventListener('swUpdated', handleServiceWorkerUpdate);
  });

  const reloadPageWithBypassCache = () => {
    window.location.href = window.location.href.split('?')[0] + '?nocache=' + new Date().getTime();
  };

  const handleServiceWorkerUpdate = (event: Event) => {
    const customEvent = event as CustomEvent;
    if (customEvent.detail) {
      isUpdateAvailable.value = true;
    }
  };
</script>

<style>
.update-notification {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffeb3b;
  text-align: center;
  padding: 1em;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

html, body {
  overscroll-behavior: none;
}
</style>
