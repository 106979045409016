<script setup lang="ts">
  import { Pencil, Trash2, MoreHorizontal } from 'lucide-vue-next'
  import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger
  } from '@/components/ui/dropdown-menu'
  import { Button } from '@/components/ui/button'
  import {ref, watch} from "vue";
  import DeleteDialog from "@/components/tables/DeleteDialog.vue";
  import useEventsBus from "@/utils/eventBus";
  import MastersEditForm from "@/components/tables/masters/MastersEditForm.vue";

  const props = defineProps<{
    object: any,
    entity: string
  }>()

  const isDeleteDialogOpen = ref(false);
  const isEditDialogOpen = ref(false);
  const { bus } = useEventsBus();

  const setIsEditDialogOpen = (value: boolean) => {
    isEditDialogOpen.value = value;
  };

  const setIsDeleteDialogOpen = (value: boolean) => {
    isDeleteDialogOpen.value = value;
  };

  watch(() => bus.value.get('update_'+props.entity), async () => {
    setIsDeleteDialogOpen(false);
    setIsEditDialogOpen(false);
  })
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <Button variant="ghost" class="w-8 h-8 p-0">
        <MoreHorizontal class="w-4 h-4" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">
      <DropdownMenuLabel>{{ $t('table.actions') }}</DropdownMenuLabel>
      <DropdownMenuItem @click.prevent="setIsEditDialogOpen(true)" class="cursor-pointer">
        <Pencil class="mr-2 h-4 w-4" />
        {{ $t('table.edit') }}
      </DropdownMenuItem>
      <DropdownMenuItem @click.prevent="setIsDeleteDialogOpen(true)" class="cursor-pointer">
        <Trash2 class="mr-2 h-4 w-4" />
        {{ $t('table.delete') }}
      </DropdownMenuItem>
    </DropdownMenuContent>
    <MastersEditForm :entity="props.entity" :object="props.object" :open="isEditDialogOpen" :set-open="setIsEditDialogOpen" />
    <DeleteDialog :entity="props.entity" :object="props.object" :open="isDeleteDialogOpen" :set-open="setIsDeleteDialogOpen" />
  </DropdownMenu>
</template>

<style scoped>

</style>
