import { h } from 'vue'
import { ColumnDef } from "@tanstack/vue-table";
import {i18n} from "@/utils/i18n";
import {User} from "@/models/tables/user.interface";

export const userColumns: ColumnDef<User>[] = [
    {
        accessorKey: 'name',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.name')),
        cell: ({ row }) => {
            const name = String(row.original.name);
            const surname = String(row.original.surname);
            const formattedName = name+' '+surname;
            return h('div', { class: 'text-right font-medium' }, formattedName)
        },
    },
    {
        accessorKey: 'email',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.email')),
        cell: ({ row }) => {
            const email = String(row.getValue('email'));
            return h('div', { class: 'text-right font-medium' }, email)
        },
    },
]
