import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import SigninView from "@/views/signin/SigninView.vue";
import DashboardView from "@/views/DashboardView.vue";
import BuildingTableView from "@/views/tables/BuildingTableView.vue";
import UserTableView from "@/views/tables/UserTableView.vue";
import ManagerTableView from "@/views/tables/ManagerTableView.vue";
import MasterTableView from "@/views/tables/MasterTableView.vue";
import ForgotPasswordView from "@/views/signin/ForgotPasswordView.vue";
import ResetPasswordView from "@/views/signin/ResetPasswordView.vue";
import NewBuildingView from "@/views/building/NewBuildingView.vue";
import ViewBuildingView from "@/views/building/ViewBuildingView.vue";
import CameraView from "@/views/CameraView.vue";
import CoverageElementsTableView from "@/views/tables/CoverageElementsTableView.vue";
import DefectTableView from "@/views/tables/DefectTableView.vue";
import ServiceOrderTableView from "@/views/tables/ServiceOrderTableView.vue";
import ServiceOrderView from "@/views/ServiceOrderView.vue";
import ViewRoofView from "@/views/roof/ViewRoofView.vue";
import PhotoRoofView from "@/views/roof/PhotoRoofView.vue";
import RciView from "@/views/RciView.vue";
import FoldersView from '@/views/category/FoldersView.vue';
import DocumentsView from "@/views/DocumentsView.vue";
import MatrixTableView from "@/views/tables/MatrixTableView.vue";
import PhotoDefectView from "@/views/defect/PhotoDefectView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'signin',
    component: SigninView
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: ForgotPasswordView
  },
  {
    path: '/reset/:email/:token',
    name: 'reset',
    component: ResetPasswordView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/camera/:id/:entity/:serviceOrderId?',
    name: 'camera',
    component: CameraView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/table/buildings',
    name: 'buildingsTable',
    component: BuildingTableView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/table/users',
    name: 'usersTable',
    component: UserTableView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/table/managers',
    name: 'managersTable',
    component: ManagerTableView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/table/masters',
    name: 'mastersTable',
    component: MasterTableView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/table/coverage-elements',
    name: 'coverageElementsTable',
    component: CoverageElementsTableView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/table/defects',
    name: 'defectsTable',
    component: DefectTableView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/table/matrix',
    name: 'matrixTable',
    component: MatrixTableView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/table/service-orders',
    name: 'serviceOrdersTable',
    component: ServiceOrderTableView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/service-order/:entity/:id/:serviceOrderId',
    name: 'serviceOrder',
    component: ServiceOrderView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/building/:id',
    name: 'newBuilding',
    component: NewBuildingView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/building/view/:id',
    name: 'viewBuilding',
    component: ViewBuildingView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/roof/view/:id',
    name: 'viewRoof',
    component: ViewRoofView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/roof/photo/:entity/:id',
    name: 'photoRoof',
    component: PhotoRoofView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/defect/photo/:entity/:serviceOrderId',
    name: 'photoDefects',
    component: PhotoDefectView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documents/:entity/:id',
    name: 'documentsView',
    component: DocumentsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rci/:id',
    name: 'rci',
    component: RciView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/category',
    name: 'foldersManage',
    component: FoldersView,
    meta: {
      requiresAuth: true
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
