<script setup lang="ts">
  import {Check, ChevronsUpDown, ImagePlus, Image, File, FilePlus} from "lucide-vue-next";
  import {FormControl, FormField, FormItem, FormLabel, FormMessage} from "@/components/ui/form";
  import {Separator} from "@/components/ui/separator";
  import {Button} from "@/components/ui/button";
  import {Switch} from "@/components/ui/switch";
  import {Input} from "@/components/ui/input";
  import {Textarea} from "@/components/ui/textarea";
  import {toTypedSchema} from "@vee-validate/zod";
  import * as z from "zod";
  import {useForm} from "vee-validate";
  import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from '@/components/ui/popover'
  import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
  } from '@/components/ui/command'
  import TablesService from "@/services/tables/tables.service";
  import {onMounted, ref, watch} from "vue";
  import {i18n} from "@/utils/i18n";
  import {cn, toast} from "@/utils/utils";
  import router from "@/router";
  import {useRoofStore} from "@/store/roof.store";
  import UploadDocumentDialog from "@/components/building/UploadDocumentDialog.vue";

  const props = defineProps<{
    id: any,
    entity: string
  }>()
  const emit = defineEmits(['enableRoofs'])
  let building: any = {};
  let managers: any = [];
  let masters: any = [];
  const roofStore = useRoofStore();
  const mastersOpen = ref(false);
  const managersOpen = ref(false);
  const isDocumentDialogOpen = ref(false);

  const buildingSchema = toTypedSchema(z.object({
    name: z.string(),
    code: z.string(),
    address: z.string(),
    city: z.string(),
    zip: z.string(),
    province: z.string(),
    state: z.string(),
    yearConstruction: z.number(),
    activity: z.string(),
    description: z.string(),
    master: z.number(),
    manager: z.number(),
    enabled: z.boolean().default(true)
  }))

  const { handleSubmit, values, setValues } = useForm({
    validationSchema: buildingSchema
  })

  watch(values, (newFormData) => {
    building = newFormData;
  });

  async function getManagers() {
    const res = await TablesService.getData('managers')
    managers = res?.data
  }

  async function getMasters() {
    const res = await TablesService.getData('masters')
    masters = res?.data
  }

  const setIsDocumentDialogOpen = (value: boolean) => {
    isDocumentDialogOpen.value = value;
  };

  const onSubmitBuilding = handleSubmit(async () => {
    let res;
    if (props.id === '0') {
      res = await TablesService.createRecord(building, props.entity);
      if (res?.data.id) {
        emit('enableRoofs', res.data.id);
        await router.push({name: 'newBuilding', params: {id: res.data.id}});
        await initializeForm();
        toast.success(i18n.global.t('toast.insert_success'));
      }
    } else {
      res = await TablesService.updateRecord(building, props.entity);
      if (res?.data) {
        toast.success(i18n.global.t('toast.edit_success'));
      }
    }
  })

  onMounted(async () => {
    await getManagers();
    await getMasters();
    if (props.id !== '0') {
      await initializeForm();
    }
  })

  async function initializeForm() {
    const res = await TablesService.getElementById(props.entity, props.id);
    if (res?.data) {
      res.data.manager = res.data.manager?.id;
      res.data.master = res.data.master?.id;
      setValues(res.data);
      await roofStore.setRoofList(res.data.roofings);
    }
  }
</script>

<template>
  <form @submit.prevent="onSubmitBuilding">
    <div class="mb-[5%]">
      <div class="flex gap-4">
        <FormField v-slot="{ componentField }" name="name">
          <FormItem class="w-full">
            <FormLabel>{{ $t('column.name') }}</FormLabel>
            <FormControl>
              <Input
                  v-bind="componentField"
                  :placeholder="$t('column.name')"
                  type="text"
                  auto-correct="off"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="code">
          <FormItem class="w-full">
            <FormLabel>{{ $t('column.code') }}</FormLabel>
            <FormControl>
              <Input
                  v-bind="componentField"
                  :placeholder="$t('column.code')"
                  type="text"
                  auto-correct="off"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <Separator class="mt-3" />
      <div class="w-full bg-gray-400 p-2">
        <h3 class="text-lg font-semibold">{{ $t('building.location') }}</h3>
      </div>
      <div class="flex gap-4">
        <FormField v-slot="{ componentField }" name="address">
          <FormItem class="w-full">
            <FormLabel>{{ $t('column.address') }}</FormLabel>
            <FormControl>
              <Input
                  v-bind="componentField"
                  :placeholder="$t('column.address')"
                  type="text"
                  auto-correct="off"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="city">
          <FormItem class="w-full">
            <FormLabel>{{ $t('column.city') }}</FormLabel>
            <FormControl>
              <Input
                  v-bind="componentField"
                  :placeholder="$t('column.city')"
                  type="text"
                  auto-correct="off"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <div class="flex gap-4">
        <FormField v-slot="{ componentField }" name="zip">
          <FormItem class="w-full">
            <FormLabel>{{ $t('column.zip') }}</FormLabel>
            <FormControl>
              <Input
                  v-bind="componentField"
                  :placeholder="$t('column.zip')"
                  type="text"
                  auto-correct="off"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="province">
          <FormItem class="w-full">
            <FormLabel>{{ $t('column.province') }}</FormLabel>
            <FormControl>
              <Input
                  v-bind="componentField"
                  :placeholder="$t('column.province')"
                  type="text"
                  auto-correct="off"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="state">
          <FormItem class="w-full">
            <FormLabel>{{ $t('column.state') }}</FormLabel>
            <FormControl>
              <Input
                  v-bind="componentField"
                  :placeholder="$t('column.state')"
                  type="text"
                  auto-correct="off"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <Separator class="mt-3" />
      <div class="w-full bg-gray-400 p-2">
        <h3 class="text-lg font-semibold">{{ $t('building.generality') }}</h3>
      </div>
      <div class="flex gap-4">
        <FormField v-slot="{ componentField }" name="yearConstruction">
          <FormItem class="w-full">
            <FormLabel>{{ $t('column.yearConstruction') }}</FormLabel>
            <FormControl>
              <Input
                  v-bind="componentField"
                  :placeholder="$t('column.yearConstruction')"
                  type="number"
                  auto-correct="off"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="activity">
          <FormItem class="w-full">
            <FormLabel>{{ $t('column.activity') }}</FormLabel>
            <FormControl>
              <Input
                  v-bind="componentField"
                  :placeholder="$t('column.activity')"
                  type="text"
                  auto-correct="off"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <div>
        <FormField v-slot="{ componentField }" name="description">
          <FormItem class="w-full">
            <FormLabel>{{ $t('column.description') }}</FormLabel>
            <FormControl>
                  <Textarea
                      v-bind="componentField"
                      :placeholder="$t('column.description')"
                  />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>
      </div>
      <Separator class="mt-3" />
      <div class="w-full bg-gray-400 p-2">
        <h3 class="text-lg font-semibold">{{ $t('building.master_and_manager') }}</h3>
      </div>
        <div class="flex gap-4 mt-2">
          <FormField name="master">
            <FormItem class="flex flex-col w-full">
              <FormLabel>{{ $t('column.master') }}</FormLabel>
              <Popover v-model:open="mastersOpen">
                <PopoverTrigger as-child>
                  <FormControl>
                    <Button
                        variant="outline"
                        role="combobox"
                        :class="cn('w-full justify-between', !values.master && 'text-muted-foreground')"
                    >
                      {{ values.master ? masters.find(
                        (master: any) => master.id === values.master,
                    )?.name : i18n.global.t('table.select') }}
                      <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent class="w-[45vw] p-0">
                  <Command>
                    <CommandInput :placeholder="$t('table.search')" />
                    <CommandEmpty>{{$t('table.no_result')}}</CommandEmpty>
                    <CommandList>
                      <CommandGroup>
                        <CommandItem
                            v-for="master in masters"
                            :key="master.id"
                            :value="master.id"
                            @select="() => {
                              setValues({
                                master: master.id,
                              })
                              mastersOpen = false
                            }"
                        >
                          <Check
                              :class="cn('mr-2 h-4 w-4', master.id === values.master ? 'opacity-100' : 'opacity-0')"
                          />
                          {{ master.name }}
                        </CommandItem>
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          </FormField>
          <FormField name="manager">
            <FormItem class="flex flex-col w-full">
              <FormLabel>{{ $t('column.manager') }}</FormLabel>
              <Popover v-model:open="managersOpen">
                <PopoverTrigger as-child>
                  <FormControl>
                    <Button
                        variant="outline"
                        role="combobox"
                        :class="cn('w-full justify-between', !values.manager && 'text-muted-foreground')"
                    >
                      {{ values.manager ? managers.find(
                        (manager: any) => manager.id === values.manager,
                    )?.name : i18n.global.t('table.select') }}
                      <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent class="w-[45vw] p-0">
                  <Command>
                    <CommandInput :placeholder="$t('table.search')" />
                    <CommandEmpty>{{$t('table.no_result')}}</CommandEmpty>
                    <CommandList>
                      <CommandGroup>
                        <CommandItem
                            v-for="manager in managers"
                            :key="manager.id"
                            :value="manager.id"
                            @select="() => {
                              setValues({
                                manager: manager.id,
                              })
                              managersOpen = false
                            }"
                        >
                          <Check
                              :class="cn('mr-2 h-4 w-4', manager.id === values.manager ? 'opacity-100' : 'opacity-0')"
                          />
                          {{ manager.name }}
                        </CommandItem>
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>
      <Separator class="my-3" />
      <FormField v-slot="{ value, handleChange }" name="enabled">
        <FormItem class="flex flex-row items-center gap-4 p-4">
          <div class="space-y-0.5">
            <FormLabel class="text-base">
              {{ $t('table.enabled') }}
            </FormLabel>
          </div>
          <FormControl>
            <Switch :checked="value" @update:checked="handleChange" />
          </FormControl>
        </FormItem>
      </FormField>
      <Separator class="my-3" />
      <div class="w-full bg-gray-400 p-2 mb-2">
        <h3 class="text-lg font-semibold">{{ $t('building.images') }}</h3>
      </div>
      <div class="flex w-full gap-4">
        <Button @click.prevent="router.push({ name: 'camera', params: { id: building.id, entity: 'buildings' }})" :disabled="props.id === '0'" type="button" class="w-full text-center">
          <ImagePlus class="mr-2 h-5 w-5" />
          {{ $t('building.add_photo') }}
        </Button>
        <Button @click.prevent="$router.push({ name: 'photoRoof', params: { entity:'buildings', id: props.id } })" :disabled="props.id === '0'" type="button" class="w-full text-center">
          <Image class="mr-2 h-5 w-5" />
          {{ $t('building.gallery') }}
        </Button>
      </div>
      <Separator class="my-3" />
      <div class="w-full bg-gray-400 p-2 mb-2">
        <h3 class="text-lg font-semibold">{{ $t('building.documents') }}</h3>
      </div>
      <div class="flex w-full gap-4">
        <Button @click.prevent="setIsDocumentDialogOpen(true)" :disabled="props.id === '0'" type="button" class="w-full text-center">
          <FilePlus class="mr-2 h-5 w-5" />
          {{ $t('building.add_document') }}
        </Button>
        <UploadDocumentDialog :entity="props.entity" :entity-id="props.id" :open="isDocumentDialogOpen" :set-open="setIsDocumentDialogOpen" @edit="setIsDocumentDialogOpen(false)" />
        <Button @click.prevent="$router.push({ name: 'documentsView', params: { entity:'buildings', id: props.id } })" :disabled="props.id === '0'" type="button" class="w-full text-center">
          <File class="mr-2 h-5 w-5" />
          {{ $t('building.document_gallery') }}
        </Button>
      </div>
      <Separator class="my-6" />
      <Button type="submit" class="w-full">
        {{ $t('table.save') }}
      </Button>
    </div>
  </form>
</template>

<style scoped>

</style>
