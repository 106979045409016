import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import {createPinia} from "pinia";
import {i18n} from "@/utils/i18n";
import mitt from "mitt";
import Toast, {PluginOptions, POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";
import "vue-multiselect/dist/vue-multiselect.css";

// Vuetify
import '@mdi/font/css/materialdesignicons.css'
//import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labsComponents from 'vuetify/labs/components'

import './assets/css/app.css';
import {useNavStore} from "@/store/nav.store";

const app = createApp(App);
const pinia = createPinia()
app.use(pinia);
const navStore = useNavStore();
app.config.globalProperties.$navStore = navStore;
router.beforeEach((to, from, next) => {
    if (to.name === 'signin' || to.name === 'forgot' || to.name === 'reset' || to.name === 'camera') {
        navStore.setNav(false)
    } else {
        navStore.setNav(true)
    }
    if (!to.meta.requiresAuth) {
        next();
        return;
    }
    if (!localStorage.getItem('user')) {
        next({ name: 'signin' })
    } else {
        next()
    }
})
app.use(router);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.use(i18n);

const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  directives,
  theme: false
})
app.use(vuetify);
const options: PluginOptions = {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    transition: "Vue-Toastification__slideBlurred",
    maxToasts: 5,
    showCloseButtonOnHover: true,
    newestOnTop: true,
    hideProgressBar: true,
    closeButton: false,
    icon: true,
    rtl: false
};
app.use(Toast, options);

app.mount('#app');
