<script setup lang="ts">
  import {Pencil, Trash2, MoreHorizontal, Eye, FilePlus} from 'lucide-vue-next'
  import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger
  } from '@/components/ui/dropdown-menu'
  import { Button } from '@/components/ui/button'
  import {ref} from "vue";
  import DeleteDialog from "@/components/tables/DeleteDialog.vue";
  import router from "@/router";
  import UploadDocumentDialog from "@/components/building/UploadDocumentDialog.vue";

  const props = defineProps<{
    object: any,
    entity: string
  }>()

  const isDeleteDialogOpen = ref(false);
  const isDocumentDialogOpen = ref(false);

  const setIsDocumentDialogOpen = (value: boolean) => {
    isDocumentDialogOpen.value = value;
  };

  const setIsDeleteDialogOpen = (value: boolean) => {
    isDeleteDialogOpen.value = value;
  };

  const editBuilding = async () => {
    await router.push({name: 'newBuilding', params: {id: props.object.id}});
  }
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <Button variant="ghost" class="w-8 h-8 p-0">
        <MoreHorizontal class="w-4 h-4" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">
      <DropdownMenuLabel>{{ $t('table.actions') }}</DropdownMenuLabel>
      <DropdownMenuItem @click.prevent="editBuilding" class="cursor-pointer">
        <Pencil class="mr-2 h-4 w-4" />
        {{ $t('table.edit') }}
      </DropdownMenuItem>
      <DropdownMenuItem @click.prevent="$router.push({ name: 'viewBuilding', params: { id: props.object.id }})" class="cursor-pointer">
        <Eye class="mr-2 h-4 w-4" />
        {{ $t('table.view') }}
      </DropdownMenuItem>
      <DropdownMenuItem @click.prevent="setIsDocumentDialogOpen(true)" class="cursor-pointer">
        <FilePlus class="mr-2 h-4 w-4" />
        {{ $t('building.add_document') }}
      </DropdownMenuItem>
      <DropdownMenuItem @click.prevent="setIsDeleteDialogOpen(true)" class="cursor-pointer">
        <Trash2 class="mr-2 h-4 w-4" />
        {{ $t('table.delete') }}
      </DropdownMenuItem>
    </DropdownMenuContent>
    <DeleteDialog :entity="props.entity" :object="props.object" :open="isDeleteDialogOpen" :set-open="setIsDeleteDialogOpen" />
    <UploadDocumentDialog :entity="props.entity" :entity-id="props.object.id" :open="isDocumentDialogOpen" :set-open="setIsDocumentDialogOpen" @edit="setIsDocumentDialogOpen(false)" />
  </DropdownMenu>
</template>

<style scoped>

</style>
