<script setup lang="ts">
  import {Button} from "@/components/ui/button";
  import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter, DialogHeader, DialogTitle
  } from "@/components/ui/dialog";
  import {Input} from "@/components/ui/input";
  import {onMounted, watch} from "vue";
  import TablesService from "@/services/tables/tables.service";
  import {Switch} from "@/components/ui/switch";
  import useEventsBus from "@/utils/eventBus";
  import PasswordInput from "@/components/ui_custom/input/PasswordInput.vue";
  import {Separator} from "@/components/ui/separator";
  import {toTypedSchema} from "@vee-validate/zod";
  import * as z from "zod";
  import {useForm} from "vee-validate";
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from '@/components/ui/form'
  import {i18n} from "@/utils/i18n";

  const props = defineProps<{
    object: any,
    entity: string,
    open: boolean,
    setOpen: any,
  }>()
  const { emit } = useEventsBus();
  let user: any = {};
  const formSchema = toTypedSchema(z.object({
    name: z.string(),
    surname: z.string(),
    email: z.string().email(),
    password: z.string(),
    confirmPassword: z.string(),
    enabled: z.boolean().default(true)
  })
      .refine((data) => data.password === data.confirmPassword , { message: i18n.global.t('validations.sameAsPassword'), path: ['confirmPassword'] })
  )
  const { handleSubmit, values, setValues } = useForm({
    validationSchema: formSchema,
  })

  watch(values, (newFormData) => {
    user = newFormData;
  });

  const onSubmit = handleSubmit(async () => {
    let res;
    if (!props.object.id) {
      res = await TablesService.createRecord(user, props.entity);
    } else {
      res = await TablesService.updateRecord(user, props.entity);
    }
    if (res != undefined) {
      emit('update_'+props.entity, res.data);
    }
  })

  watch(() => props.open, async(_, newValue) => {
    if (newValue === false) {
      if (props.object.id) {
        const object = props.object;
        object.confirmPassword = object.password;
        setValues(object);
      }
    }
  },{ immediate: true })
</script>

<template>
  <Dialog :open="props.open" @update:open="props.setOpen">
    <DialogContent class="max-w-[60vw]">
      <DialogHeader>
        <DialogTitle v-if="!props.object.id">{{ $t('table.add') }}</DialogTitle>
        <DialogTitle v-else>{{ $t('table.edit') }}</DialogTitle>
        <form @submit="onSubmit" class="mt-3">
          <div class="flex gap-4">
            <FormField v-slot="{ componentField }" name="name">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.name') }}</FormLabel>
                <FormControl>
                  <Input type="text" :placeholder="$t('column.name')" v-bind="componentField" auto-correct="off" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="surname">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.surname') }}</FormLabel>
                <FormControl>
                  <Input type="text" :placeholder="$t('column.surname')" v-bind="componentField" auto-correct="off" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
          <Separator class="my-1" />
          <div class="flex gap-4">
            <FormField v-slot="{ componentField }" name="email">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.email') }}</FormLabel>
                <FormControl>
                  <Input type="email" :placeholder="$t('column.email')" v-bind="componentField" auto-correct="off" auto-capitalize="none" auto-complete="email" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
          <Separator class="my-1" />
          <div class="flex gap-4" v-if="!props.object.id">
            <FormField v-slot="{ componentField }" name="password">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.password') }}</FormLabel>
                <FormControl>
                  <PasswordInput :placeholder="$t('column.password')" v-bind="componentField" auto-correct="off" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="confirmPassword">
              <FormItem class="w-full">
                <FormLabel>{{ $t('column.confirm_password') }}</FormLabel>
                <FormControl>
                  <PasswordInput :placeholder="$t('column.confirm_password')" v-bind="componentField" auto-correct="off" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
          <Separator class="my-1" v-if="!props.object.id" />
          <FormField v-slot="{ value, handleChange }" name="enabled">
            <FormItem class="flex flex-row items-center gap-4 p-4">
              <div class="space-y-0.5">
                <FormLabel class="text-base">
                  {{ $t('table.enabled') }}
                </FormLabel>
              </div>
              <FormControl>
                <Switch :checked="value" @update:checked="handleChange" />
              </FormControl>
            </FormItem>
          </FormField>
          <DialogFooter>
            <DialogClose as-child>
              <Button type="button" variant="secondary">
                {{ $t('table.cancel') }}
              </Button>
            </DialogClose>
            <Button type="submit">
              {{ $t('table.confirm') }}
            </Button>
          </DialogFooter>
        </form>
      </DialogHeader>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
