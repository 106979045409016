import { h } from 'vue'
import { ColumnDef } from "@tanstack/vue-table";
import DropdownAction from '@/components/tables/coverage_elements/CoverageElementsDataTableDropDown.vue'
import {i18n} from "@/utils/i18n";
import {CoverageElement} from "@/models/tables/coverageElement.interface";

export const columns: ColumnDef<CoverageElement>[] = [
    {
        accessorKey: 'id',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.id')),
        cell: ({ row }) => {
            const id = Number(row.getValue('id'));
            return h('div', { class: 'text-right font-medium' }, id)
        },
    },
    {
        accessorKey: 'code',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.code')),
        cell: ({ row }) => {
            const code = String(row.getValue('code'));
            return h('div', { class: 'text-right font-medium' }, code)
        },
    },
    {
        accessorKey: 'description',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('column.description')),
        cell: ({ row }) => {
            const description = String(row.getValue('description'));
            return h('div', { class: 'text-right font-medium' }, description)
        },
    },
    {
        accessorKey: 'enabled',
        header: () => h('div', { class: 'text-right' }, i18n.global.t('table.enabled')),
        cell: ({ row }) => {
            const enabled = Boolean(row.getValue('enabled'));
            let i18nEnabled = i18n.global.t('table.disabled');
            if (enabled) {
                i18nEnabled = i18n.global.t('table.enabled');
            }
            return h('div', { class: 'text-right font-medium' }, i18nEnabled)
        },
    },
    {
        id: 'actions',
        cell: ({ row }) => {
            const object = row.original
            return h('div', { class: 'relative' }, h(DropdownAction, { object, entity: 'coverage-elements' }))
        },
    },
]
