import axios from "axios";
import {toast} from "@/utils/utils";

export default class OrderServiceService {
    static async createMultipleCoverageData(body: any) {
        try {
            return await axios.post(process.env.VUE_APP_BASE_API_URL + 'coverage-data/bulk', body, {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e.response.data.message);
        }
    }

    static async updateCoverageDataByServiceOrder(coverageElements: any) {
        try {
            return await axios.post(process.env.VUE_APP_BASE_API_URL + 'coverage-data/delete', coverageElements, {
                headers: {
                    Authorization: process.env.VUE_APP_API_KEY
                }
            });
        } catch (e: any) {
            toast.error(e?.response.data.message);
        }
    }

}
