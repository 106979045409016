<script setup lang="ts">
import BuildingCarousel from "@/components/building/view/components/BuildingCarousel.vue";
import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/card";
import {onMounted, ref} from "vue";
import TablesService from "@/services/tables/tables.service";
import {useRoute} from "vue-router";
import BuildingRoofCarousel from "@/components/building/view/components/BuildingRoofCarousel.vue";
import {File, FilePlus} from "lucide-vue-next";
import {Button} from "@/components/ui/button";
import UploadDocumentDialog from "@/components/building/UploadDocumentDialog.vue";

const route = useRoute();
let loading = ref(true);
let building: any = ref({})
const isDocumentDialogOpen = ref(false);

const setIsDocumentDialogOpen = (value: boolean) => {
  isDocumentDialogOpen.value = value;
};

onMounted(async () => {
  const res = await TablesService.getElementById('buildings', route.params.id)
  if (res?.data) {
    building.value = res.data
    loading.value = false;
  }
})
</script>

<template>
  <div class="w-5/6 h-[100vh] p-[2%]">
    <div class="md:flex h-full">
      <div class="md:w-3/12">
        <Card class="h-[50%]">
          <CardHeader class="p-5 bg-gray-400">
            <CardTitle class="text-lg">
              {{ $t('building.master') }}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.name') }}:</p>
                <p> {{ building.master?.name }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.code') }}:</p>
                <p> {{ building.master?.code }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.address') }}:</p>
                <p> {{ building.master?.address }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.zip') }}:</p>
                <p> {{ building.master?.zip }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.city') }}:</p>
                <p> {{ building.master?.city }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.state') }}:</p>
                <p> {{ building.master?.state }}</p>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card class="mt-4 h-[50%]">
          <CardHeader class="p-5 bg-gray-400">
            <CardTitle class="text-lg">
              {{ $t('building.manager') }}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.name') }}:</p>
                <p> {{ building.manager?.name }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.code') }}:</p>
                <p> {{ building.manager?.code }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.address') }}:</p>
                <p> {{ building.manager?.address }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.zip') }}:</p>
                <p> {{ building.manager?.zip }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.city') }}:</p>
                <p> {{ building.manager?.city }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.state') }}:</p>
                <p> {{ building.manager?.state }}</p>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div class="md:w-6/12 grid justify-center items-center text-center justify-items-center">
        <BuildingCarousel entity="buildings" :entity-id="route.params.id as string" />
        <p class="font-bold ml-4 text-xl">Tetti</p>
        <BuildingRoofCarousel v-if="loading === false" entity="coverages" :data="building.roofings" />
      </div>
      <div class="md:w-3/12">
        <Card class="h-full">
          <CardHeader class="p-5 bg-gray-400">
            <CardTitle class="text-lg">
              {{ $t('building.info') }}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.name') }}:</p>
                <p> {{ building.name }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.code') }}:</p>
                <p> {{ building.code }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.address') }}:</p>
                <p> {{ building.address }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.zip') }}:</p>
                <p> {{ building.zip }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.city') }}:</p>
                <p> {{ building.city }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.state') }}:</p>
                <p> {{ building.state }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.yearConstruction') }}:</p>
                <p> {{ building.yearConstruction }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.sustainability') }}:</p>
                <p> {{ building.buildingSustainability }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.description') }}:</p>
                <p> {{ building.description }}</p>
              </div>
              <div class="flex">
                <p class="font-bold mr-1">{{ $t('column.activity') }}:</p>
                <p> {{ building.activity }}</p>
              </div>
              <Button @click.prevent="$router.push({ name: 'documentsView', params: { entity:'buildings', id: building.id } })" type="button" class="w-full text-center mt-6">
                <File class="mr-2 h-5 w-5" />
                {{ $t('building.document_gallery') }}
              </Button>
              <Button @click.prevent="setIsDocumentDialogOpen(true)" type="button" class="w-full text-center mt-6">
                <FilePlus class="mr-2 h-5 w-5" />
                {{ $t('building.add_document') }}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  </div>
  <UploadDocumentDialog entity="buildings" :entity-id="route.params.id" :open="isDocumentDialogOpen" :set-open="setIsDocumentDialogOpen" @edit="setIsDocumentDialogOpen(false)" />
</template>

<style scoped>

</style>
