<script setup lang="ts">
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "@/components/ui/carousel";
import {Card, CardContent} from "@/components/ui/card";
import {StepBack, X} from "lucide-vue-next";
import DeleteDialog from "@/components/tables/DeleteDialog.vue";
import {Button} from "@/components/ui/button";
import {onMounted, ref, watch} from "vue";
import {watchOnce} from "@vueuse/core";
import TablesService from "@/services/tables/tables.service";
import {useRoute} from "vue-router";
import Spinner from "@/components/ui_custom/spinner/Spinner.vue";
import useEventsBus from "@/utils/eventBus";
import {toast} from "@/utils/utils";
import {i18n} from "@/utils/i18n";

const photos: any = ref([]);
const route = useRoute();
const emblaMainApi = ref<CarouselApi>()
const emblaThumbnailApi = ref<CarouselApi>()
const selectedIndex = ref(0)
const isDeleteDialogOpen = ref(false);
const { bus } = useEventsBus()
let isLoading = ref(false);
let obj = {}

const setIsDeleteDialogOpen = (value: boolean, photoId: number) => {
  isDeleteDialogOpen.value = value;
  obj = { id: photoId };
};

function onSelect() {
  if (!emblaMainApi.value || !emblaThumbnailApi.value)
    return
  selectedIndex.value = emblaMainApi.value.selectedScrollSnap()
  emblaThumbnailApi.value.scrollTo(emblaMainApi.value.selectedScrollSnap())
}

function onThumbClick(index: number) {
  if (!emblaMainApi.value || !emblaThumbnailApi.value)
    return
  emblaMainApi.value.scrollTo(index)
}

watch(() => bus.value.get('delete_attachments'), async (value) => {
  photos.value = photos.value.filter(( photo: any ) => photo.id != value);
  toast.success(i18n.global.t('toast.delete_success'));
})

watchOnce(emblaMainApi, (emblaMainApi) => {
  if (!emblaMainApi)
    return

  onSelect()
  emblaMainApi.on('select', onSelect)
  emblaMainApi.on('reInit', onSelect)
})

onMounted(async () => {
  isLoading.value = true;
  const res = await TablesService.getAttachments(route.params.entity as string, route.params.id as string, 'photos');
  photos.value = res?.data
  isLoading.value = false;
})
</script>

<template>
  <Spinner
      :is-loading="isLoading"
  />
    <div v-if="photos.length > 0" class="m-[2%] w-5/6">
      <Button @click.prevent="$router.back()" type="button" class="text-center">
        <StepBack class="mr-2 h-5 w-5" />
        {{ $t('building.back') }}
      </Button>
      <div class="w-full sm:w-auto grid justify-center h-full">
        <Carousel
            class="relative w-full max-w-lg"
            @init-api="(val: any) => emblaMainApi = val"
        >
          <CarouselContent>
            <CarouselItem class="cursor-pointer" v-for="photo in photos" :key="photo.id">
              <div class="p-1">
                <Card>
                  <CardContent class="flex aspect-square items-center justify-center p-0">
                    <img :src="`data:${photo.mimeType};base64, ${photo.data}`" :alt="photo.fileName" class="h-[100%] w-[100%] object-cover rounded-lg">
                    <Button class="absolute p-2 rounded-full bg-gray-200 text-red-700" @click.prevent="setIsDeleteDialogOpen(true, photo.id)">
                      <X class="h-6 w-6" />
                    </Button>
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>

        <Carousel
            class="relative w-full max-w-lg"
            @init-api="(val: any) => emblaThumbnailApi = val"
        >
          <CarouselContent class="flex gap-1 ml-0">
            <CarouselItem v-for="(photo, index) in photos" :key="photo.id" class="pl-0 basis-1/4 cursor-pointer" @click="onThumbClick(index)">
              <div class="p-1" :class="index === selectedIndex ? '' : 'opacity-50'">
                <Card>
                  <CardContent class="flex aspect-square items-center justify-center p-0">
                    <img :src="`data:${photo.mimeType};base64, ${photo.data}`" :alt="photo.filename" class="h-[100%] w-[100%] object-cover rounded-lg">
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          </CarouselContent>
        </Carousel>
      </div>
    </div>
    <div v-else class="m-[2%] w-5/6">
      <Button @click.prevent="$router.back()" type="button" class="text-center">
        <StepBack class="mr-2 h-5 w-5" />
        {{ $t('building.back') }}
      </Button>
      <div class="content-center h-full">
        <p class="text-7xl text-gray-500 font-bold text-center uppercase">
          {{ $t('building.no_foto') }}
        </p>
      </div>
    </div>
  <DeleteDialog entity="attachments" :object="obj" :open="isDeleteDialogOpen" :set-open="setIsDeleteDialogOpen" />
</template>

<style scoped>

</style>
