<script setup lang="ts">
  import { Trash2, MoreHorizontal } from 'lucide-vue-next'
  import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger
  } from '@/components/ui/dropdown-menu'
  import { Button } from '@/components/ui/button'
  import useEventsBus from "@/utils/eventBus";

  const props = defineProps<{
    object: any,
  }>()

  const { emit } = useEventsBus()
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <Button variant="ghost" class="w-8 h-8 p-0">
        <MoreHorizontal class="w-4 h-4" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">
      <DropdownMenuLabel>{{ $t('table.actions') }}</DropdownMenuLabel>
      <DropdownMenuItem @click.prevent="emit('delete_rci', props.object)" class="cursor-pointer">
        <Trash2 class="mr-2 h-4 w-4" />
        {{ $t('table.delete') }}
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>

<style scoped>

</style>
