<script setup lang="ts">
import { Pencil, Trash2, MoreHorizontal, Image, FileCode2, ListChecks } from 'lucide-vue-next'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import {ref, watch} from "vue";
import DeleteDialog from "@/components/tables/DeleteDialog.vue";
import useEventsBus from "@/utils/eventBus";
import ServiceOrdersEditForm from "@/components/tables/service_orders/ServiceOrdersEditForm.vue";
import axios from 'axios';

const props = defineProps<{
  object: any,
  entity: string
}>()

const isDeleteDialogOpen = ref(false);
const isEditDialogOpen = ref(false);
const { bus } = useEventsBus();

const getExcel = async () => {

  try {
    const res = await axios.get(process.env.VUE_APP_BASE_API_URL + 'service-order/excel/'+props.object.id, {
      headers: {
        Authorization: process.env.VUE_APP_API_KEY
      },
      responseType: 'blob'
    });

    // Creare un URL per il blob
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.xlsx'); // Nome del file da salvare
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Rimuovere il link dopo il download
  } catch (error) {
    console.error('Errore nel download del file', error);
  }
};


const setIsEditDialogOpen = (value: boolean) => {
  isEditDialogOpen.value = value;
};

const setIsDeleteDialogOpen = (value: boolean) => {
  isDeleteDialogOpen.value = value;
};

watch(() => bus.value.get('update_'+props.entity), async () => {
  setIsDeleteDialogOpen(false);
  setIsEditDialogOpen(false);
})
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <Button variant="ghost" class="w-8 h-8 p-0">
        <MoreHorizontal class="w-4 h-4" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">
      <DropdownMenuLabel>{{ $t('table.actions') }}</DropdownMenuLabel>
      <DropdownMenuItem @click.prevent="setIsEditDialogOpen(true)" class="cursor-pointer">
        <Pencil class="mr-2 h-4 w-4" />
        {{ $t('table.edit') }}
      </DropdownMenuItem>
      <DropdownMenuItem @click.prevent="$router.push({ name: 'serviceOrder', params: { entity: 'coverages', id: props.object.coverage.id, serviceOrderId: props.object.id }})" class="cursor-pointer">
        <ListChecks class="mr-2 h-4 w-4" />
        {{ $t('table.execute') }}
      </DropdownMenuItem>
      <DropdownMenuItem @click.prevent="$router.push({ name: 'photoDefects', params: { entity: 'defects', serviceOrderId: props.object.id }})" class="cursor-pointer">
        <Image class="mr-2 h-4 w-4" />
        {{ $t('building.defects_gallery') }}
      </DropdownMenuItem>
      <DropdownMenuItem @click.prevent="getExcel()" class="cursor-pointer">
        <FileCode2 class="mr-2 h-4 w-4"></FileCode2>
        {{ $t('table.excel') }}
      </DropdownMenuItem>
      <DropdownMenuItem @click.prevent="setIsDeleteDialogOpen(true)" class="cursor-pointer">
        <Trash2 class="mr-2 h-4 w-4" />
        {{ $t('table.delete') }}
      </DropdownMenuItem>

    </DropdownMenuContent>
    <ServiceOrdersEditForm :entity="props.entity" :object="props.object" :open="isEditDialogOpen" :set-open="setIsEditDialogOpen" />
    <DeleteDialog :entity="props.entity" :object="props.object" :open="isDeleteDialogOpen" :set-open="setIsDeleteDialogOpen" />
  </DropdownMenu>
</template>

<style scoped>

</style>
