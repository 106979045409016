<script setup lang="ts">
import {onMounted, reactive, ref} from "vue";
  import TablesService from "@/services/tables/tables.service";
  import {useRoute} from "vue-router";
  import {useForm} from "vee-validate";
  import { Button } from '@/components/ui/button'
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from '@/components/ui/form'
  import { Checkbox } from '@/components/ui/checkbox'
  import {Input} from "@/components/ui/input";
  import OrderServiceService from "@/services/order_service/orderService.service";
  import router from "@/router";
  import {ImagePlus} from "lucide-vue-next";
  import {toast} from "@/utils/utils";
  import {i18n} from "@/utils/i18n";
  import Spinner from "@/components/ui_custom/spinner/Spinner.vue";

  const route = useRoute();
  let coverage: any = reactive({ elements: [] });
  let isLoading = ref(false);
  async function getCoverage() {
    const res = await TablesService.getCoverageServiceOrderById(route.params.entity as string, route.params.id, route.params.serviceOrderId);
    coverage.elements = res?.data;
  }

  const { handleSubmit, setValues } = useForm()

  function createBody(defects: any) {
    let body: any = {
      bulk: []
    }
    Object.keys(defects).forEach(key => {
      let obj: any = {}
      if (defects[key].id) {
        const ids = defects[key].id.split(';')
        obj.defect = JSON.parse(ids[0])
        obj.defectDimensions = defects[key].dimensions
        obj.defectTotal = defects[key].total
        obj.coverage = {
          id: coverage.elements.id,
          dimensions: coverage.elements.dimensions,
          rci: coverage.elements.rci
        }
        obj.coverageElement = Number(ids[1]);
        obj.serviceOrder = Number(route.params.serviceOrderId);
        body.bulk.push(obj);
      }
    })
    return body;
  }

  const onSubmit = handleSubmit(async (values) => {
    isLoading.value = true;
    const body = createBody(values.defects);
    if (coverage.elements.coverageData?.length > 0) {
      await OrderServiceService.updateCoverageDataByServiceOrder(coverage.elements.coverageData);
    }
    const res = await OrderServiceService.createMultipleCoverageData(body);
    if (res?.status === 201) {
      await getCoverage();
      if (coverage.elements.coverageData?.length > 0) {
        setValues(await initializeForm());
      }
      isLoading.value = false;
      toast.success(i18n.global.t('toast.insert_success'));
    }
    isLoading.value = false;
  })

  function initializeForm() {
    const obj: any = { defects: {} };
    coverage.elements.coverageData.forEach((element: any) => {
      const id = JSON.parse(JSON.stringify(element.defect))
      obj.defects['defect'+element.defect.id] = { id: JSON.stringify(id)+';'+element.coverageElement.id, dimensions: element.defectDimensions, total: element.defectTotal }
    });
    return obj;
  }

  async function openCamera(defectId: number) {
    await onSubmit();
    await router.push({
      name: 'camera',
      params: {id: +defectId, entity: 'defects', serviceOrderId: route.params.serviceOrderId}
    })
  }

  onMounted(async () => {
    await getCoverage();
    if (coverage.elements.coverageData?.length > 0) {
      setValues(await initializeForm());
    }
  })
</script>

<template>
  <Spinner
      :is-loading="isLoading"
  />
  <form class="w-full m-[2%]" @submit="onSubmit">
    <p class="text-lg pb-5 font-semibold">{{ $t('menu.service_order' )}}</p>
    <FormField v-for="(element, index) in coverage.elements.coverageElements" :key="index" name="defects">
      <FormItem class="mb-10">
        <div class="mb-4">
          <FormLabel class="text-base font-bold">
            {{ element.code }}
          </FormLabel>
          <p>
            {{ element.description }}
          </p>
        </div>
        <div v-for="(defect, index) in element.defects" :key="index" class="w-full">
          <div class="flex my-2 cols-2">
            <div class="mr-2 max-w-fit">
              <FormLabel class="text-base font-semibold">
                {{ defect.code }}
              </FormLabel>
            </div>
            <p class="w-[75%]">
              {{ defect.description }}
            </p>
          </div>
          <div class="flex w-full">
            <FormField v-slot="{ value, handleChange }" type="checkbox" :value="JSON.stringify(defect) + ';' + element.id" :unchecked-value="false" :name="'defects.defect'+ defect.id + '.id'">
              <FormItem class="flex flex-row space-x-3 space-y-0 w-[5%] items-center">
                <FormControl>
                  <Checkbox
                      :checked="value"
                      @update:checked="handleChange"
                  />
                </FormControl>
              </FormItem>
            </FormField>
            <div class="flex w-full gap-4">
              <FormField v-slot="{ componentField }" :name="'defects.defect'+ defect.id +'.dimensions'">
                <FormItem class="w-[80%]">
                  <FormControl>
                    <Input
                        v-bind="componentField"
                        :placeholder="$t('column.defect_dimensions')"
                        type="text"
                        auto-correct="off"
                    />
                  </FormControl>
                </FormItem>
              </FormField>
              <FormField v-slot="{ componentField }" :name="'defects.defect'+ defect.id +'.total'">
                <FormItem class="w-[80%]">
                  <FormControl>
                    <Input
                        v-bind="componentField"
                        :placeholder="$t('column.total')"
                        type="text"
                        auto-correct="off"
                    />
                  </FormControl>
                </FormItem>
              </FormField>
            </div>
            <div class="w-[20%] ml-4">
              <Button @click.prevent="openCamera(+defect.id)" :disabled="coverage.elements.coverageData?.length === 0" type="button" class="text-center w-full">
                <ImagePlus class="mr-2 h-5 w-5" />
                {{ $t('building.add_photo') }}
              </Button>
            </div>
          </div>
          <FormMessage />
        </div>
      </FormItem>
    </FormField>
    <div class="flex mt-4 w-full justify-center">
      <Button type="submit" class="w-[90%]">
        {{ $t('table.save') }}
      </Button>
    </div>
  </form>
</template>

<style scoped>

</style>
